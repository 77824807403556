//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapMutations } from 'vuex'
import fieldLabelAndTooltip from '~/mixins/fieldLabelAndTooltip.js'
import { getDictWP,
         getGPPParameters } from '~/utils/butterUtils'
import { event } from 'vue-gtag'

export default {
  name: 'DAGPP',
  mixins: [fieldLabelAndTooltip],
  props: [ 'daMember',
           'validations',
           'dict' ],
  data () {
    return {
      baseClass: {
        'is-medium': true,
        'is-outlined': false,
        button: true
      },
      isRegistrationOpen: null,
      isRequestingGPPBallot: null,
    }
  },
  computed: {
    showCodeFragmentMark () {
      return this.$store.state.showCodeFragmentMark
    },
    lang () {
      return this.$i18n.locale.toLowerCase()
    },
    currentRequest () {
      return this.$store.getters["requests/getCurrent"] || {};
    },
    GPPParameters () {
      return getGPPParameters(this.$store, this.lang, this.$store.state.showDictionaryKeys)
    },
  },
  methods: {
    getDictWP(dictItem, paramObj) {
      /**
       * 2022-12-04 John Yee
       * This construction looks weird - like a recursive call; but, it's not.
       * The "getDictWP(dictItem, paramObj)" in the return statement is really
       * the function getDictWP that is imported from ~/utils/butterUtils.js
       * 
       * reference: https://stackoverflow.com/questions/52332993/calling-a-function-from-a-helper-in-vue-template
       */
      return getDictWP(dictItem, paramObj)
    },
    getDisplayDate (date) {
      const Months = [
        this.dict.L04.substring(0,3),
        this.dict.L05.substring(0,3),
        this.dict.L06.substring(0,3),
        this.dict.L07.substring(0,3),
        this.dict.L08.substring(0,3),
        this.dict.L09.substring(0,3),
        this.dict.L10.substring(0,3),
        this.dict.L11.substring(0,3),
        this.dict.L12.substring(0,3),
        this.dict.L13.substring(0,3),
        this.dict.L14.substring(0,3),
        this.dict.L15.substring(0,3)
      ]

      const [yyyy,mm,dd] = date.split('T')[0].split('-')
      const MMM = Months[parseInt(mm)-1]
      return dd+' '+MMM+' '+yyyy
    },
    set_request_ballot (value) {
      this.isRequestingGPPBallot = value
      const da = { democratsAbroad :
                    {
                      isExistingMember: this.currentRequest.democratsAbroad.isExistingMember,
                      isRequestingToJoin: this.currentRequest.democratsAbroad.isRequestingToJoin,
                      isRequestingGPPBallot: value
                    }
                 }
      if (value) {
        event('gpp_requested')
      }
      this.$store.commit('requests/update', da)
      this.$emit('input')
    },
    ...mapGetters('requests', ['getCurrent']),
    ...mapMutations('requests', ['update'])
  },
  async mounted () {
    /**
     * "GPPTimeZone" is the time zone used for registration start datetime, etc.
     * 
     * we get the current datetime using "GPPTimeZone" and worldtimeapi, which returns
     * the current time in the "GPPTimeZone"
     * 
     * now we do not need to account for the timezone because all times are with respect
     * to the same timezone (the "GPP Time Zone") so we can pretend we are at timezone UTC+0
     * 
     * this means we can use "new Date()" without a timezone offset
     * it also means we have to remove the timezone offset from the worldtimeapi response
     */
    const USTimeZones = {
        eastern: "America/New_York",
        central: "America/Chicago",
        mountain: "America/Denver",
        pacific_coast: "America/Los_Angeles",
        alaska: "America/Anchorage",
        hawaii: "Pacific/Honolulu"
      }
    const dateStart      = new Date(this.GPPParameters.registration_datetime_start).getTime()
    const dateEnd        = new Date(this.GPPParameters.registration_datetime_end).getTime()
    const GPPTimeZone    = USTimeZones[this.GPPParameters.datetime_timezone]
    const resp           = await fetch("https://worldtimeapi.org/api/timezone/"+GPPTimeZone)
    const GPPTimeZoneObj = await resp.json()
    let datim = GPPTimeZoneObj.datetime.slice(0,21) // remove the time zone offset
    let today = new Date(datim).getTime()+parseInt(sessionStorage.getItem("timeWarpOffset"))

    this.isRegistrationOpen = dateStart<=today && today<=dateEnd
    this.$emit('GPPRegistration', this.isRegistrationOpen)

    if (this.currentRequest.democratsAbroad) {
      this.isRequestingGPPBallot = this.currentRequest.democratsAbroad.isRequestingGPPBallot
    }
  },
}
