//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { getDictFLJ } from '~/utils/butterUtils'
import { PREFER_NOT_TO_ANSWER,
         getVFAParameters } from '~/utils/VFAParameters'
import { mapGetters } from 'vuex'

export default {
  props: [
    'value',
    'join',
    'type',
    'label',
    'toolTipTitle',
    'toolTipContent',
    'joinLabel',
    'joinToolTipTitle',
    'joinToolTipContent',
    'stateRules',
    'validations',
    'maxlengthFields',
    'dict'
  ],
  data () {
    return {
      baseClass: {
        'is-medium': true,
        'is-outlined': false,
        button: true
      },
      isOpen: false,
      isToolTipOpen: false,
      joinToolTipIsOpen: false,
      partyChoices: {
        Democratic: { aliases: ['democratic', 'democrat'] },
        Republican: { aliases: ['republican', 'gop', 'grand old party'] },
        // Independent: {aliases: ['independent', 'ind', 'i']},
        none: { aliases: ['none', 'no party'] },
        other: { aliases: ['other', 'another party'] },
        [ PREFER_NOT_TO_ANSWER ]: { aliases: [ PREFER_NOT_TO_ANSWER ] }
      },
      isOtherBannedParty: true,
      isOtherButNoValue: false,
      joinButton: null,
      isExistingMember: null,
      isRequestingToJoin: null,
    }
  },
  computed: {
    showCodeFragmentMark () {
      return this.$store.state.showCodeFragmentMark
    },
    isStudentSite () {
      return process.env.isStudentSite === 'true' || this.$store.state.isSimulatedStudentSite
    },
    party: {
      get () {
        return this.getCurrent.party
      },
      set (value) {
        if (value && this.ban_from_joining.includes(value.replace(/\s/g, '').toLowerCase())) {
          this.$store.commit('requests/removeProperty',
            [ 'joinDa', 'DaFirstName', 'DaMiddleName', 'DaLastName', 'DaEmail', 'DaMobile', 'DaPhone', 'DaOptinEmail', 'DaOptinMobile', 'DaOptinPhone',
              'do_not_call', 'do_not_contact', 'email_opt_in', 'phone_opt_in', 'mobile_opt_in'
            ])

          this.$emit('bannedParty', true)
        } else {
          this.$emit('bannedParty', false)
        }
        this.$store.commit('requests/update', { party: value })
      }
    },
    email () {
      return this.getCurrent.email || null
    },
    membershipStatus: {
      get () {
        return (this.getCurrent.democratsAbroad !== undefined) ? this.getCurrent.democratsAbroad.isExistingMember : null
      },
      set (value) {
        this.$store.commit('requests/update', { democratsAbroad : { isExistingMember: value, isRequestingToJoin: this.joiningStatus, isRequestingGPPBallot: null } })
        this.$emit('isExistingMember', value)
      }
    },
    joiningStatus: {
      get () {
        return (this.getCurrent.democratsAbroad !== undefined) ? this.getCurrent.democratsAbroad.isRequestingToJoin : null
      },
      set (value) {
        this.$store.commit('requests/update', { democratsAbroad : { isExistingMember: this.membershipStatus, isRequestingToJoin: value, isRequestingGPPBallot: null } })
        this.$emit('isRequestingToJoin', value)
      }
    },
    joinValue: {
      get () {
        return this.getCurrent.joinDa
      },
      set (value) {
        this.$store.commit('requests/update', { joinDa: value })
        if ((this.getCurrent.joinDa===true || this.getCurrent.joinDa==='already a member') && this.email) {
          this.$store.commit('requests/update', { DaEmail: this.email })
        }
        this.$emit('isJoiningJoinedDa', value)
      }
    },
    isOther: {
      get () {
        return Boolean(
          Object.keys(this.partyChoices)
            .map(x => this.partyChoices[x].aliases)
            .reduce((a, b) => a.concat(b), [])
            .indexOf(
              this.party
                ? this.party.toString().toLowerCase()
                : ''
            ) === -1 &&
            (this.party || this.isOtherButNoValue)
        )
      }
    },
    ban_from_joining () {
      return this.VFAParameters.BAN_FROM_DEMOCRATS_ABROAD.replace(/\s/g, '').toLowerCase().split(',')
    },
    ban_from_joining_str () {
      return this.VFAParameters.BAN_FROM_DEMOCRATS_ABROAD.replace(/\s/g, '').toLowerCase()
    },
    VFAParameters () {
      return getVFAParameters(this.$store)
    },
    ...mapGetters('requests', ['getCurrent'])
  },
  methods: {
    clearJoinDA () {
      this.joinValue = false
      this.joinButton = null
      this.$store.commit('requests/removeProperty', [ 'joinDa' ])
      this.$store.commit('requests/update', { democratsAbroad: { isExistingMember: null, isRequestingToJoin: null } })
    },
    clearOptIn () {
      this.$store.commit('requests/update', { 'DaEmail': null, 'DaMobile': null, 'DaPhone': null }) /** 2023-11-14 JY This line looks obsolete given the next line which removes the properties. */
      this.$store.commit('requests/removeProperty', [ 'email_opt_in', 'mobile_opt_in', 'phone_opt_in', 'DaEmail', 'DaMobile', 'DaPhone' ])
      if (this.getCurrent.joinDa===null) {
        this.$store.commit('requests/removeProperty', [ 'joinDa' ])
      }
    },
    clearDaNames () {
      this.$store.commit('requests/removeProperty', [ 'DaFirstName', 'DaMiddleName', 'DaLastName' ])
    },
    populateDaNamesWithVFANames () {
      this.$store.commit('requests/update',
        {
          'DaFirstName': this.getCurrent.firstName,
          'DaMiddleName': this.getCurrent.middleName || '',
          'DaLastName': this.getCurrent.lastName
        }
      )
    },
    selectOther (value) {
      this.party = ''
      this.isOtherButNoValue = value
    },
    checkOtherParty () {
      this.isOtherBannedParty = this.ban_from_joining.includes(this.party.replace(/\s/g, '').toLowerCase())
    },
    getDictFLJ(dictItem, dict) {
      /**
       * 2022-12-04 John Yee
       * This construction looks weird - like a recursive call; but, it's not.
       * The "getDictFLJ(dictItem, dict)" in the return statement is really
       * the function getDictFLJ(dictItem, dict) that is imported from ~/utils/butterUtils.js
       * 
       * reference: https://stackoverflow.com/questions/52332993/calling-a-function-from-a-helper-in-vue-template
       */
      return getDictFLJ(dictItem, dict)
    },
  },
  mounted () {
    if (this.getCurrent.democratsAbroad !== undefined) {
      this.isExistingMember = this.getCurrent.democratsAbroad.isExistingMember
      this.isRequestingToJoin = this.getCurrent.democratsAbroad.isRequestingToJoin

      if (this.isExistingMember) {
        this.joinButton = 'already a member'
      }
      if (this.isRequestingToJoin) {
        this.joinButton = true
      }
    } else {
        this.joinButton = null
    }
  },
}
