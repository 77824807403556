//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from "vuex"
import { getVFAParameters } from '~/utils/VFAParameters'
import { getNewVfaDate } from '~/utils/butterUtils'
import fieldLabelAndTooltip from '~/mixins/fieldLabelAndTooltip.js'

const OFFSET_DUE_TO_OPTION_SELECT_MONTH = 1

export default {
  name: "date-of-birth",
  mixins: [fieldLabelAndTooltip],
  props: [
    "value",
    "message",
    "v",
    "label",
    "toolTipTitle",
    "toolTipContent",
    "dict"
  ],
  data() {
    return {
      dateMMMDDYYYY: "",
      tempDate: null,
      toolTipOpen: false,
      monthNames: [
        this.dict.L16,
        this.dict.L04,
        this.dict.L05,
        this.dict.L06,
        this.dict.L07,
        this.dict.L08,
        this.dict.L09,
        this.dict.L10,
        this.dict.L11,
        this.dict.L12,
        this.dict.L13,
        this.dict.L14,
        this.dict.L15,
      ],
      indexOfListOfYears: 0,
      indexOfMonthNames: 0,
      indexOfDays: 0,
      year: "",
      month: "",
      day: ""
    }
  },
  created() {
    if (this.dob) {
      this.tempDate = this.dob
      this.year = this.dob.getFullYear().toString()
      this.month = ("0"+(this.dob.getMonth() + 1).toString()).slice(-2)
      this.day = ("0"+this.dob.getDate().toString()).slice(-2)
    }
  },
  computed: {
    showCodeFragmentMark() {
      return this.$store.state.showCodeFragmentMark
    },
    dateFormat() {
      return this.$i18n.locales.filter((item) => item.code === this.$i18n.locale)[0].iso
    },
    listOfYears() {
      const arrayOfYears = [this.dict.H39]
      const earliestYear = this.electionYear - this.VOTER_AGE_OLDEST
      for (let i = this.electionYear - 18; i >= earliestYear; i--) {
        arrayOfYears.push(i)
      }
      return arrayOfYears
    },
    listOfMonths() {
      const arrayOfMonths = [this.dict.L16]
      let lastMonth = (this.year < this.electionYear - 18) ? 12 : 11
      for (let i = 1; i <= lastMonth; i++) {
        arrayOfMonths.push(this.monthNames[i])
      }
      return arrayOfMonths
    },
    listOfDays() {
      const arrayOfDays = [this.dict.B50]
      let lastDayOfMonth = this.getMaxMonthDay(this.year, this.month)
      if (this.year == this.electionYear-18 && this.month == 11) {
        lastDayOfMonth = this.electionDay
      }
      for (let i = 1; i <= lastDayOfMonth; i++) {
        arrayOfDays.push(('0'+i.toString(10)).slice(-2))
      }
      return arrayOfDays
    },
    dob: {
      get() {
        let dob = this.getCurrent.dob
        function createDateObj(d) {
          /**
           * 2022-09-28 John Yee
           * old code...
           * if dob==="", then this function returns "Thu Nov 30 1899 00:00:00 GMT-{UTCofset} ({name of timezone})"
           * because new Date("", "-1", "") returns "Thu Nov 30 1899..."
           * 
           * new code...
           * if dob==="", then return null
           */
          return dob ? new Date(d.slice(0, 4), d.slice(5, 7) - 1, d.slice(8, 10)) : null
        }
        return typeof dob === "string" ? createDateObj(dob) : undefined
      },
      set(val) {
        if (val && val instanceof Date && val!='Invalid Date') {
          this.$emit("test", val.getDate()) /** 2023-04-29 John Yee. This line may be unnecessary.  It looks like it was used as a test for something that is now unknown. */
          function createDateString(d) {
            return `${d.getFullYear()}-${d.getMonth() < 9 ? "0" : ""}${d.getMonth() + 1}-${d.getDate() < 10 ? "0" : ""}${d.getDate()}`
          }
          let d = val instanceof Date ? createDateString(val) : null
          this.$store.commit("requests/update", { dob: d })
          this.$emit("input", d)

          this.tempDate = val
          this.dateMMMDDYYYY = this.dateFormatter(val)
          const valDate = new Date(val)
          this.indexOfListOfYears = this.listOfYears.indexOf(valDate.getFullYear())
          this.indexOfMonthNames = valDate.getMonth()+OFFSET_DUE_TO_OPTION_SELECT_MONTH
          this.indexOfDays = valDate.getDate()
        } else {
          // if the val is not a valid date or empty, then start over
          this.dateMMMDDYYYY = ""
          this.tempDate = null
          this.indexOfListOfYears = 0
          this.indexOfMonthNames = 0
          this.indexOfDays = 0
          this.year = ""
          this.month = ""
          this.day = ""
          this.$store.commit("requests/update", { dob: "" })
          this.$emit("input", "")
        }
      },
    },
    VFAParameters () {
      return getVFAParameters(this.$store)
    },
    VOTER_AGE_OLDEST () {
      return parseInt(this.VFAParameters.VOTER_AGE_OLDEST)
    },
    electionDate () {
      return this.getElectionDate()
    },
    electionYear () {
      return parseInt(this.electionDate.slice(0,4), 10)
    },
    electionDay () {
      return parseInt(this.electionDate.slice(9,10), 10)
    },
    ...mapGetters("requests", ["getCurrent"]),
  },
  methods: {
    setTempDate () {
      if (this.year && this.month && this.day) {
        const isPastLastMonth = parseInt(this.month, 10)>this.listOfMonths.length-1
        const isPastLastDayOfMonth = parseInt(this.day, 10)>this.listOfDays.length-1

        if (isPastLastMonth || isPastLastDayOfMonth) {
          this.tempDate = null
        } else {
          this.dateMMMDDYYYY = this.monthNames[parseInt(this.month, 10)]+" "+this.day+", "+this.year
          const MM = ("0"+this.month).slice(-2)
          const DD = ("0"+this.day).slice(-2)
          this.tempDate = new Date(this.year+"-"+MM+"-"+DD+"T00:00:00")
        }
      } else {
        this.tempDate = null
      }
    },
    changeDay (day) {
      if (day<1) {
        this.tempDate = null
      } else {
        this.day = day.toString()
        this.setTempDate()
      }
    },
    changeMonth(month) {
      if (month) {
        if (month<1) {
          this.tempDate = null
        } else {
          this.month = month.toString()
          this.setTempDate()
        }
      } else {
        this.tempDate = null
      }
    },
    changeYear(year) {
      if (year<1) {
        this.tempDate = null
      } else {
        this.year = year.toString()
        this.setTempDate()
      }
    },
    dateFormatter(d) {
      let ahead2HoursDate = new Date(d.getFullYear(), d.getMonth(), d.getDate(), 2)
      return ahead2HoursDate.toLocaleDateString(this.dateFormat, {
        year: "numeric",
        month: "long",
        day: "numeric",
      })
    },
    getMaxMonthDay(year, month) {
      let maxDay = 31
      if (month == 4 || month == 6 || month == 9 || month == 11) {
        maxDay = 30
      } else {
        if (month == 2) {
          maxDay = 28

          if ((year % 4) == 0) {
            maxDay = 29
          }
          if ((year % 100) == 0 && (year % 400) != 0) {
            maxDay = 28
          }
        }
      }
      return maxDay
    },
    getElectionDate() {
      const eDay = ["4","3","2","8","7","6","5"]
      // const today = new Date()
      const today = getNewVfaDate()
      let YYYY = today.getFullYear()
      let Nov02Date

      if (YYYY % 2 == 1) {
        YYYY++
      } else {
        Nov02Date = new Date(YYYY+"-11-02T00:00:00")
        const electionDayThisYear = new Date(YYYY+"-11-0"+eDay[Nov02Date.getDay()]+"T00:00:00")
        if (today>electionDayThisYear) {YYYY+=2}
      }
      Nov02Date = new Date(YYYY+"-11-02T00:00:00")

      return YYYY+"-11-0"+eDay[Nov02Date.getDay()]+"T00:00:00"
    },
  },
  watch: {
    tempDate(val) {
      if (val && val instanceof Date) {
        this.$emit("tempDate", val)
        this.dob = val
      } else {
        this.dateMMMDDYYYY = null
        this.dob = null
        this.year = ""
        this.month = ""
        this.day = ""
      }
    },
  },
}
