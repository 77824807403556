//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { getDictFLJ,
         getDictWP } from '~/utils/butterUtils'
import {mask, TheMask} from 'vue-the-mask'
export default {
  name: 'id-input',
  directives: {mask},
  components: {TheMask},
  props: [
    'label',
    'value',
    'instructions',
    'toolTipTitle',
    'toolTipContent',
    'idOptions',
    'maxlengthFields',
    'validations',
    'dict'
  ],
  data () {
    return {
      toolTipOpen: false,
      temp: undefined,
      hasIdentification: false,
      baseClass: {
        'is-small': true,
        'is-outlined': false,
        button: true
      },
    }
  },
  computed: {
    showCodeFragmentMark () {
      return this.$store.state.showCodeFragmentMark
    },
    labelSuffix () {
      return (this.idOptions || this.idOptions.length>0) ? " ("+this.dict.E36+")" : " ("+this.dict.E35+")"
    },
    ssn: {
      get () { return this.value && this.value.ssn ? this.value.ssn : null },
      set (val) { this.update({ssn: val}) }
    },
    ssn4: {
      get () { return this.value && this.value.ssn4 ? this.value.ssn4 : null },
      set (val) { this.update({ssn4: val}) }
    },
    ssn9: {
      get () { return this.value && this.value.ssn9 ? this.value.ssn9 : null },
      set (val) { this.update({ssn9: val}) }
    },
    stateId: {
      get () { return this.value && this.value.stateId ? this.value.stateId : null },
      set (val) { this.update({stateId: val}) }
    },
    noId: {
      get () { return this.value && this.value.noId ? this.value.noId : false },
      set (val) {
        if (val) {
          this.temp = this.value
          this.$emit('input', {
            ssn: null,
            ssn4: null,
            ssn9: null,
            stateId: null,
            noId: true
          })
        } else {
          this.$emit('input', this.temp)
          this.temp = undefined
        }
        // this.update({noId: val})
      }
    },
    usesStateId: function () { return Boolean(this.stateIDTypes && this.stateIDTypes.length > 0) },
    stateIDTypes: function () {
      if (!this.idOptions) {
        return null
      }
      return this.idOptions.filter(opt => opt !== 'SSN4' && opt !== 'SSN9' && opt !== 'SSN')
    },
    stateIdLabel: function () {
      return !this.usesStateId
        ? this.dict.E48
        : "type of document and number of your "+this.stateIDTypes.reduce((label, cur, i, arr) => {
          if (i === 0) {
            return label + this.getDictFLJ(`request.id.${cur}`, this.dict)
          } else if (i === arr.length - 1) {
            return `${label} ${this.dict.E44} ${this.getDictFLJ(`request.id.${cur}`, this.dict)}`
          } else {
            return `${label}, ${this.getDictFLJ(`request.id.${cur}`, this.dict)}`
          }
        }, '')
    }
  },
  methods: {
    update (val) {
      // console.log(val)
      let newVal = Object.assign({}, this.value, val)
      if (Object.keys(val).includes('ssn') && val.ssn) { newVal.ssn4 = null; newVal.ssn9 = null; newVal.noId = false }
      if (Object.keys(val).includes('ssn4') && val.ssn4) { newVal.ssn = null; newVal.noId = false }
      if (Object.keys(val).includes('ssn9') && val.ssn9) { newVal.ssn = null; newVal.noId = false }
      if (Object.keys(val).includes('stateId') && val.stateId) { newVal.ssn = null; newVal.noId = false }
      // if (val.noId) {
      //   this.temp = this.value
      //   newVal.ssn4 = null
      //   newVal.ssn = null
      //   newVal.stateId = null
      // }
      // if (Object.keys(val).includes('noId') && val.noId === false && this.temp) { newVal = Object.assign({}, this.temp); this.temp = undefined }
      // console.log('fin', newVal)
      this.$emit('input', newVal)
    },
    getDictFLJ(dictItem, dict) {
      /**
       * 2022-12-04 John Yee
       * This construction looks weird - like a recursive call; but, it's not.
       * The "getDictFLJ(dictItem, dict)" in the return statement is really
       * the function getDictFLJ(dictItem, dict) that is imported from ~/utils/butterUtils.js
       * 
       * reference: https://stackoverflow.com/questions/52332993/calling-a-function-from-a-helper-in-vue-template
       */
      return getDictFLJ(dictItem, dict)
    },
    getDictWP(dictItem, paramObj) {
      /**
       * 2022-12-04 John Yee
       * This construction looks weird - like a recursive call; but, it's not.
       * The "getDictWP(dictItem, paramObj)" in the return statement is really
       * the function getDictWP that is imported from ~/utils/butterUtils.js
       * 
       * reference: https://stackoverflow.com/questions/52332993/calling-a-function-from-a-helper-in-vue-template
       */
      return getDictWP(dictItem, paramObj)
    },
  }
}
