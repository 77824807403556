import { render, staticRenderFns } from "./FormSalutation.vue?vue&type=template&id=c6cc31de&"
import script from "./FormSalutation.vue?vue&type=script&lang=js&"
export * from "./FormSalutation.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {At: require('/opt/build/repo/components/At.js').default})
