//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { getDictFLJ,
         getDictWP } from '~/utils/butterUtils'
import { mapGetters } from 'vuex'
import fieldLabelAndTooltip from '~/mixins/fieldLabelAndTooltip.js'

export default {
  name: 'Gender',
  mixins: [fieldLabelAndTooltip],
  props: [
    'label',
    'fieldName',
    'toolTipTitle',
    'toolTipContent',
    'value',
    'genderRule',
    'state',
    'validations',
    'dict'
  ],
  data () {
    return {
      baseClass: {
        'is-medium': true,
        'is-outlined': false,
        button: true
      }
    }
  },
  computed: {
    showCodeFragmentMark () {
      return this.$store.state.showCodeFragmentMark
    },
    sex: {
      get () {
        return this.getCurrent.sex || null
      },
      set (value) {
        this.$store.commit('requests/update', { sex: value })
      }
    },
    ...mapGetters('requests', ['getCurrent'])
  },
  methods: {
    getDictFLJ(dictItem, dict) {
      /**
       * 2022-12-04 John Yee
       * This construction looks weird - like a recursive call; but, it's not.
       * The "getDictFLJ(dictItem, dict)" in the return statement is really
       * the function getDictFLJ(dictItem, dict) that is imported from ~/utils/butterUtils.js
       * 
       * reference: https://stackoverflow.com/questions/52332993/calling-a-function-from-a-helper-in-vue-template
       */
      return getDictFLJ(dictItem, dict)
    },
    getDictWP(dictItem, paramObj) {
      /**
       * 2022-12-04 John Yee
       * This construction looks weird - like a recursive call; but, it's not.
       * The "getDictWP(dictItem, paramObj)" in the return statement is really
       * the function getDictWP that is imported from ~/utils/butterUtils.js
       * 
       * reference: https://stackoverflow.com/questions/52332993/calling-a-function-from-a-helper-in-vue-template
       */
      return getDictWP(dictItem, paramObj)
    },
  }
}
