//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { getDictFLJ,
         getDictWP,
         getNewVfaDate } from '~/utils/butterUtils'
import { STATE_ISO_CODE } from '~/utils/VFAParameters'
import axios from "axios"
import debounce from "lodash/debounce"
import { placesAutocomplete, placeDetails, uuidv4 } from "~/utils/helpers.js"
import { mapMutations, mapState } from "vuex"
import fieldLabelAndTooltip from '~/mixins/fieldLabelAndTooltip.js'

export default {
  name: "Voting-Address",
  mixins: [fieldLabelAndTooltip],
  props: ["value",
          "label",
          "validations",
          "VFAParameters",
          "fieldName",
          "instructions",
          "toolTipTitle",
          "toolTipContent",
          "findmypollingplace",
          "dict"
  ],
  data() {
    return {
      isOpen: false,
      sessionToken: "",
      tempStreet: "",
      isFetching: false,
      isFetchingCity: false,
      data: [],
      hasFocus: "",
    };
  },
  computed: {
    showCodeFragmentMark() {
      return this.$store.state.showCodeFragmentMark;
    },
    currentRequest() {
      return this.$store.getters["requests/getCurrent"] || {};
    },
    leoState() {
      return this.currentRequest.leo && this.currentRequest.leo.s
        ? this.currentRequest.leo.s
        : null;
    },
    maxlengthFields () {
      return this.VFAParameters.MAXLENGTH_FIELDS
    },
    votAdr() {
      return this.currentRequest.votAdr || {};
    },
    adr() {
      return this.currentRequest.votAdr || {};
    },
    states () {
      let s = []
      for (let ii=0; ii<STATE_ISO_CODE.length; ii++) {
        s.push({name: this.getDictFLJ( `states.${STATE_ISO_CODE[ii]}`, this.dict), iso: STATE_ISO_CODE[ii] })
      }
      return s
    },
    statesSortedLocalized() {
      return this.states
        .map((x) => ({ iso: x.iso, name: this.getDictFLJ( `states.${x.iso}`, this.dict) }))
        .sort((a, b) => a.name.localeCompare(b.name));
    },
    street: {
      get() {
        return this.votAdr.A || null;
      },
      set(value) {
        this.updateAddress("A", value);
      },
    },
    apt: {
      get() {
        return this.votAdr.B || null;
      },
      set(value) {
        this.updateAddress("B", value);
      },
    },
    city: {
      get() {
        return this.votAdr.C || null;
      },
      set(value) {
        this.updateAddress("C", value);
      },
    },
    state: {
      get() {
        return this.votAdr.S || null;
      },
      set(value) {
        this.updateAddress("S", value);
      },
    },
    zip: {
      get() {
        return this.votAdr.Z || null;
      },
      set(value) {
        this.updateAddress("Z", value.trim());  // leading or trailing spaces cause a validation error
      },
    },
    zipEx() {
      if (!this.postal.US["sub_keys"].includes(this.state)) {
        return ["99999", "99999-1001"]
      }

      let z = !this.state
        ? ["95014", "22162-1010"]
        : this.postal.US["sub_zipexs"]
            .split("~")
            [
              this.postal.US["sub_keys"]
                .split("~")
                .findIndex((x) => x === this.state)
            ].split(",")
            .map((x, i) => (i === 1 ? x + "-1010" : x));

      /**
       * If only one ZIP code, then add another example because the
       * ZIP-code help text needs two examples.
       * We simply add the first (and only) example as a ZIP+4 code.
       */
      if (z.length === 1) {
        z.push(z[0] + "-0008");
      }

      return z;
    },
    county: {
      get() {
        return this.votAdr.Y || null
      },
      set(value) {
        this.updateAddress("Y", value)
      },
    },
    LOCAL_STORAGE_SELECTED_STATE_TIME_TO_LIVE () {
      return this.VFAParameters.LOCAL_STORAGE_SELECTED_STATE_TIME_TO_LIVE
    },
    ...mapState("data", ["postal"]),
  },
  watch: {
    tempStreet: function (val, oldVal) {
      if (val !== oldVal) {
        this.street = val;
      }
    },
    state: function (newVal, oldVal) {
      // store the voting state as the selected state
      if (newVal) {
        let lsObj = JSON.stringify(Object.assign({}, this.$store.state.votingState, {'selectedState': newVal}, {'expiryTime': getNewVfaDate().getTime()+this.LOCAL_STORAGE_SELECTED_STATE_TIME_TO_LIVE*1000}))
        localStorage.setItem('localstoragevotingstate', lsObj)
        this.$store.commit('saveSelectedState', newVal)
      }

      if (newVal) this.$emit("delayTouch", "Z");
      if (
        this.leoState &&
        this.leoState.toLowerCase() !== newVal.toLowerCase()
      ) {
        this.$store.commit("requests/update", { leo: null });
      }
      if (!oldVal || newVal !== oldVal) {
        // this.county = ''
        this.$store.commit("requests/update", {
          stateSpecial: null,
          identification: null,
        });
      }
    },
    street: function (val) {
      if (val && this.tempStreet !== val) {
        this.tempStreet = val;
      }
    },
  },
  async mounted() {
    this.sessionToken = uuidv4();
    if (this.street) this.tempStreet = this.street;
    if (this.street || this.city) this.findCounty();
  },
  beforeDestroy() {
    this.county = this.county ? this.county : ""
    this.votAdr.Z = this.votAdr.Z ? this.votAdr.Z.trim() : ""
  },
  methods: {
    closeAutocomplete() {
      this.$refs.A.isActive = false;
    },
    async findCounty() {
      if (!this.county && this.state && this.city && this.state !== "DC") {
        let {
          data: { predictions },
        } = await axios.get(
          `${process.env.placesUrl + process.env.autocompleteEndpoint}?input=${
            this.street || ""
          }%20${this.city || ""}%20${this.state || ""}%20${
            this.zip || ""
          }&types=geocode&language=en&components=country:US&key=${
            process.env.placesKey
          }&session_token=${this.sessionToken}`
        );
        if (predictions.length > 0) {
          let {
            data: { result },
          } = await axios.get(
            `${process.env.placesUrl + process.env.detailsEndpoint}?placeid=${
              predictions[0].place_id
            }&key=${process.env.placesKey}&session_token=${this.sessionToken}`
          );
          this.sessionToken = uuidv4();
          let Y = result.address_components.filter((y) =>
            y.types.includes("administrative_area_level_2")
          )[0].long_name;
          this.$store.commit("requests/update", {
            votAdr: Object.assign({}, this.votAdr, {
              Y: this.decodeHtmlEntity(Y) || null,
            }),
          });
        }
      }
    },
    updateAddress: function (field, value) {
      const valueTrimmed = value ? value.trim().replace(/\s+/g, ' ') : value
      this.$store.commit("requests/update", {
        votAdr: Object.assign({}, this.votAdr, {
          [field]: this.decodeHtmlEntity(valueTrimmed) || null,
        }),
      });
      this.$emit("input");
      this.$emit("delayTouch", field);
    },
    decodeHtmlEntity(str) {
      return typeof str === "string"
        ? str.replace(/&#(\d+);/g, function (match, dec) {
            return String.fromCharCode(dec);
          })
        : str;
    },
    async getAsyncData(val) {
      await this.$nextTick();
      await this.$nextTick();
      placesAutocomplete.call(this, this.tempStreet, this.ctry, "votAdr");
    },
    fillData(opt) {
      if (opt) {
        this.$refs.B.$el.querySelector("input").focus();
        this.autocompleteFocused = false;
        placeDetails.call(this, opt);
      }
    },
    getAsyncDataCity: debounce(function () {
      this.isFetchingCity = true;
      this.data = [];
      axios
        .get(
          `${process.env.placesUrl + process.env.autocompleteEndpoint}?input=${
            this.city
          }&types=(cities)&language=en&components=country:us|country:gu|country:mp|country:pr|country:vi&key=${
            process.env.placesKey
          }&session_token=${this.sessionToken}`
        )
        .then(
          ({ data }) => {
            data.predictions.forEach((item) => this.data.push(item));
            this.isFetchingCity = false;
          },
          (response) => {
            this.isFetchingCity = false;
          }
        );
    }, 500),
    fillDataCity(option) {
      if (option && option.place_id) {
        axios
          .get(
            `${process.env.placesUrl + process.env.detailsEndpoint}?placeid=${
              option.place_id
            }&key=${process.env.placesKey}&session_token=${this.sessionToken}`
          )
          .then(({ data }) => {
            this.sessionToken = uuidv4()

            this.county = data.result.address_components.filter(
              (y) => y.types.indexOf("administrative_area_level_2") > -1
            )[0].long_name;

            /**
             * 2022-08-28 John Yee
             * US state ISO codes appear in "administrative_area_level_1"; but, for US territories
             * GU, MP, PR, VI the API returns the ISO code in "country"
             */
            let ctryISO = data.result.address_components.filter((n) => n.types.indexOf("country") > -1)[0].short_name
            this.state = (ctryISO === 'US') ? data.result.address_components.filter((n) => n.types.indexOf("administrative_area_level_1") > -1)[0].short_name : ctryISO

            data.result.adr_address
              .split(/<span class="|">|<\/span>,?\s?/)
              .filter((e) => e)
              .forEach((item, index, arr) => {
                var myRe =
                  /post-office-box|extended-address|street-address|locality|region|postal-code|country-name/g;
                if (index === 0 && !myRe.test(item)) {
                  this.extendedAddress = item;
                } else if (myRe.test(item)) {
                  switch (item) {
                    case "post-office-box":
                      // this.postOfficeBox = arr[index + 1]
                      break;
                    case "street-address":
                      this.street = arr[index + 1];
                      break;
                    case "locality":
                      this.city = arr[index + 1];
                      break;
                    case "region":
                      this.state = arr[index + 1];
                      break;
                    case "postal-code":
                      this.zip = arr[index + 1];
                      break;
                  }
                }
              });
          });
      }
    },
    getDictFLJ(dictItem, dict) {
      /**
       * 2022-12-04 John Yee
       * This construction looks weird - like a recursive call; but, it's not.
       * The "getDictFLJ(dictItem, dict)" in the return statement is really
       * the function getDictFLJ(dictItem, dict) that is imported from ~/utils/butterUtils.js
       * 
       * reference: https://stackoverflow.com/questions/52332993/calling-a-function-from-a-helper-in-vue-template
       */
      return getDictFLJ(dictItem, dict)
    },
    getDictWP(dictItem, paramObj) {
      /**
       * 2022-12-04 John Yee
       * This construction looks weird - like a recursive call; but, it's not.
       * The "getDictWP(dictItem, paramObj)" in the return statement is really
       * the function getDictWP that is imported from ~/utils/butterUtils.js
       * 
       * reference: https://stackoverflow.com/questions/52332993/calling-a-function-from-a-helper-in-vue-template
       */
      return getDictWP(dictItem, paramObj)
    },
    ...mapMutations("requests", ["update"]),
  },
};
