//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {
          getDictionary,
          getDictFLJ,
          getDictWP,
          getStateRules,
          getElections,
          getUpcomingElections,
          getLastElection,
          getDeadlines,
          getNewVfaDate,
          retrieveButter } from '~/utils/butterUtils'
import { getVFAParameters, IANA_TOP_LEVEL_DOMAINS } from '~/utils/VFAParameters'
import { required, requiredIf, maxLength, minLength, helpers, email } from 'vuelidate/lib/validators'
import { mapGetters, mapState, mapMutations } from 'vuex'
import '~/assets/css/international.scss'

const isValidTLD = (value) => { const emTLD = value ? value.trim().replaceAll("@", ".").split(".").pop() : "emptyDomain!"; return value ? IANA_TOP_LEVEL_DOMAINS.includes(emTLD) : true }
const optionalEmail = (value) => (!helpers.req(value) || email(value)) && isValidTLD(value)
const usZip = (state) =>
  helpers.withParams(
    { type: 'usZip', value: state },
    function (value, model) {
      const genericZipRegex = /^(\d{5})(?:[ -](\d{4}))?$/
      if (!state || !this.postal.US) {
        return genericZipRegex.test(value)
      } else {
        /**
         * 2022-07-23 JY
         * In "postal.US" the ZIP regular expression strings do not have a leading "^" to force a comparison
         * at the beginning of "value."  This means the ZIP string can occur at the end of "value" and
         * result in "true."
         *
         * I could insert "^" into the regular expressions in those files.  For now I have used code to
         * insert the "^" into the regular expression strings here.
         *
         * "postal.US" data are stored in ~/data/postal/US.json.
         */
        let stateZipRegexString = this.postal.US['sub_zips'].split('~')[this.postal.US['sub_keys'].split('~').findIndex(x => x === model.S)]
        stateZipRegexString = stateZipRegexString ? stateZipRegexString : '999' /* '999' is an arbitrary value in the case of a missing stateZipRegexString */
        stateZipRegexString = "^"+stateZipRegexString.replace(/\|/g,"|^")
        let stateZipRegex = new RegExp(stateZipRegexString)
        return genericZipRegex.test(value) && stateZipRegex.test(value)
      }
    }
  )
const postalCode = (state, addressType) =>
  helpers.withParams(
    { type: 'postalCode', value: state },
    function (value, model) {
      /**
       * the "postalCode" parameter "state" contains the fields of the address
       * example: state={"countryiso":"CA","A":"1 a","B":"","C":"c","S":"Nunavut","Z":"x","formatted":["1 a","c Nunavut ","CANADA"]}
       * 
       * the parameter "value" contains the contents of the postal code input box
       * the parameter "model" contains the fields of the address
       */

      /**
       * 2024-04-10 John Yee
       * An exceptional case for fwdAdr.
       * If there is nothing in the address field input boxes except for the country, then proceed as OK.
       * This suppresses error messages for fwdAdr input fields and thus makes all the fwdAdr fields optional.
       */
      if (addressType === 'fwdAdr' && (!model || (!model.A && !model.B && !model.C && !model.D && !model.S && !model.X && !model.Y && !model.Z ))) {
        return true
      }

      // if postal code is not used, then proceed as OK
      if (!model.Z) {
        return true
      }

      if (!model.countryiso || !value || !state) {
        return false
      }

      /**
       * 2024-03-31 John Yee
       * some countries do not have "sub_keys", "sub_zips", "zip"
       * e.g. no "sub_keys" - DE (Germany)
       * e.g. no "sub_zips" - DE (Germany)
       * e.g. no "zip" - AE (United Arab Emirates)
       * 
       * some countries' names are not latinized e.g. AE (United Arab Emirates)
       *  in this case use "sub_lnames"
       * 
       * some countries do not contain provinces
       *  i.e. Germany
       * 
       * some countries the province options list does not contain example postal codes
       *  i.e zipEx is null, example: United Arab Emirates
       * 
       * it does seem that all countries JSON contains the key "format"
       *  but not all countries contain "type":"Z" in "format" e.g. AE (United Arab Emirates)
       */

      /**
       * 2024-04-02 John Yee
       * my take on the hierarchy of searching for the postal code regular expression
       * 
       * start looking in "sub_zips"
       * (1) "sub_zips"  <= contains the regular expressions delimited by "~"
       * (1.1) "sub_names"
       * (1.2) "sub_lnames"
       * (1.3) "sub_keys"
       * 
       * no "sub_zips" so look in ...
       * (2) "format"
       * (2.1) "type" : "S", "options" : [ ... ]
       * (2.2) find the option such that "name" === "value"
       * (2.3) "zipRegex"  <= the value of this property is the regular expression
       * 
       * no "format" so use ...
       * (3) "zip"  <= the value of this property is the regular expression for the country
       */

      /**
       * 2022-07-23 JY
       * "this.postal[model.countryiso]" data:
       *    ~/data/postal/[model.countryiso].json   <= uses the regular expressions stored here
       * 
       * notes:
       *    ~/localization/[model.countryiso].json  <= used in store/data.js
       *    ~/static/postal/[model.countryiso].json <= I don't see any place where this is used
       */
      const countryData = this.postal[model.countryiso]

      // if no country level postal code regular expression, then proceed as OK
      if (!countryData || !countryData.zip) {
        return true
      }

      const countryZipRegexString = countryData.zip
      const countryZipRegex = new RegExp(countryZipRegexString)
      let indexOfProvince
      let provinceZipRegexString

      if (countryData.sub_zips) {
        /**
         * 2024-03-31 JY
         * sometimes the "province" is shown as the city so look for the city in the sub_names and sub_keys.
         * example: Andorra
         */

        let arrayOfSubNames = []
        if (countryData.sub_names) {
          arrayOfSubNames = countryData['sub_names'].split('~')
        }
        indexOfProvince = arrayOfSubNames.findIndex(x => x === model.S || x === model.C)

        if (indexOfProvince<0) {
          let arrayOfSubLNames = []
          if (countryData.sub_lnames) {
            arrayOfSubLNames = countryData['sub_lnames'].split('~')
          }
          indexOfProvince = arrayOfSubLNames.findIndex(x => x === model.S || x === model.C)

          if (indexOfProvince<0) {
            let arrayOfSubKeys = []
            if (countryData.sub_keys) {
              arrayOfSubKeys = countryData['sub_keys'].split('~')
            }
            indexOfProvince = arrayOfSubKeys.findIndex(x => x === model.S || x === model.C)

            if (indexOfProvince<0) {
              indexOfProvince = -1 // could not find the province
            }
          }
        }

        if (indexOfProvince>-1) {
          const sz = countryData.sub_zips.split('~')[indexOfProvince]
          provinceZipRegexString = sz ? sz : countryZipRegexString
        } else {
          provinceZipRegexString = countryZipRegexString
        }

      } else {
        // (2) look in "format"
        // all countries should have "format"; but, some defensive programming by checking it exists
        if (countryData.format) {
          const addressFields = countryData.format
          const indexOfS = addressFields.findIndex(x => x.type==='S')
          if (indexOfS>-1) {
            if (addressFields[indexOfS].options) {
              const allProvinces = addressFields[indexOfS].options
              indexOfProvince = allProvinces.findIndex(x => x.name===model.S || x.key===model.S || x.iso===model.S)
              if (indexOfProvince>-1) {
                const z = allProvinces[indexOfProvince].zipRegex
                provinceZipRegexString = z ? z : countryZipRegexString
              } else {
                provinceZipRegexString = countryZipRegexString
              }
            } else {
              provinceZipRegexString = countryZipRegexString
            }
          } else {
            provinceZipRegexString = countryZipRegexString
          }
        } else {
          // (3) use "zip"  <= the value of this property is the regular expression for the country
          provinceZipRegexString = countryZipRegexString
        }
      }

      /**
       * 2022-07-23 JY
       * In "postal[model.countryiso]" the ZIP regular expression strings do not have a leading "^"
       * to force a comparison at the beginning of "value."  This means the ZIP string can occur at
       * the end of "value" and result in "true."  We want to compare at the beginning of "value."
       *
       * Using code to insert the "^" into the regular expression strings rather than editing all
       * the country data files.
       */
      provinceZipRegexString = "^"+provinceZipRegexString.replace(/\|/g,"|^").replace("^^","^")
      const provinceZipRegex = new RegExp(provinceZipRegexString)

      /**
       * 2024-03-31 John Yee
       * provinceZipRegexString is a substring of countryZipRegexString; hence, compare the entered
       * postal code to both the province and country regular expressions because the postal code
       * must satisfy both conditions.
       */
      return ( provinceZipRegex.test(value) && (value.match(countryZipRegex) ? value==value.match(countryZipRegex)[0] : false) )
    }
  )
const addressPartRequired = (addressPart, addressType) =>
  helpers.withParams(
    { type: 'addressPartRequired', value: addressPart },
    function (value, model) {
      /**
       * 2024-04-10 John Yee
       * An exceptional case for fwdAdr.
       * If there is nothing in the address field input boxes except for the country, then proceed as OK.
       * This suppresses error messages for fwdAdr input fields and thus makes all the fwdAdr fields optional.
       */
      if (addressType === 'fwdAdr' && (!model || (!model.A && !model.B && !model.C && !model.D && !model.S && !model.Y && !model.X && !model.Z ))) {
        return true
      }

      if (!model) return true /** 2023-11-23 John Yee addresses situation where geo-coding did not return a country */
      if (!model.countryiso) return true
      let regexp = new RegExp(addressPart)
      if (this.postal[model.countryiso]) {
        return value || !regexp.test(this.postal[model.countryiso].require)
      } else {
        return true
      }
    }
  )
const countryState = (address) => 
  helpers.withParams(
    { type: 'countryState', value: address },
    function (value, model) {
      if (!model) return true /* addresses situation where geo-coding did not return a country */
      if (model.countryiso!='US') {
        /* non-US countries are not further processed */
        return true
      } else {
        /* for US - only military "states" are accepted */
        /* const validUSStates = 'Armed Forces (AA) Armed Forces (AE) Armed Forces (AP)' */
        const validUSStates = 'AA AE AP'
        return validUSStates.includes(model.S)
      }
    }
  )
const touchMap = new WeakMap()

export default {
  transition: 'test',
  middleware: 'verify-request',
  fetch ({store}) {
    store.dispatch('data/updateCountryData', 'US')
  },
  async asyncData ({ store }) {
    await retrieveButter(store, 'pages/request/_stage/index.vue asyncData ')
  },
  data () {
    return {
      hasFocus: "",
      hoveredVoterClass: null,
      isBannedParty: false,
      isValidNumberTel: true,
      isValidNumberFax: true,
      isValidNumberDaPhone: true,
      isValidNumberDaMobile: true,
      isUsesPreviousName: false,
      isPreviousNameToolTipOpen: false,
      isDaContactsToolTipOpen: false,
      isDaNamesToolTipOpen: false,
      isGPPRegistrationOpen: null,
      isJoiningJoinedDa: null,
      optedIn: true,
      skippedEmail: false,
    }
  },
  computed: {
    showTestData () {
      return this.$store.state.showTestData
    },
    showCodeFragmentMark () {
      return this.$store.state.showCodeFragmentMark
    },
    lang () {
      return this.$i18n.locale.toLowerCase()
    },
    idOptions () {
      let opts = this.stateRules && this.stateRules.id && this.stateRules.id.length > 0 && this.stateRules.id[0] !== "" ? this.stateRules.id : []
      if (opts.length===1 && opts[0]==='optional') {
        opts = []
      }

      /**
       * 2023-05-03 John Yee
       * exceptional case: if voter chooses to receive her ballot by email, the id options override the normal id options
       * To date Oklahoma is the only state that uses this case.
       * This code eliminates a hard-coded special case for Oklahoma.
      */
      let opts2 = this.stateRules && this.stateRules.id_when_recballot_email && this.stateRules.id_when_recballot_email.length > 0 && this.stateRules.id_when_recballot_email[0] !== "" ? this.stateRules.id_when_recballot_email : []
      if (opts2.length===1 && opts2[0]==='optional') {
        opts2 = []
      }

      return opts2.length>0 ? opts2 : opts
    },
    identificationStatus () {
      // this.identification comes from form-identification-input's v-model
      // it is an object of the form { "ssn": null, "ssn4": null, "ssn9": null, "stateId": null, "noId": true }
      // we have to convert noId to the text to match the slug from the Butter trigger
      return (this.identification.noId) ? "does-not-have-ssn-stateid" : "does-have-ssn-stateid"
    },
    selectedState () {
      return this.$store.state.selectedState
    },
    votState () {
      return (this.votAdr) ? this.votAdr.S : ''
    },
    voterType () {
      /*
        2024-03-12 John Yee

        voterClass values should be limited to:
          'intendToReturn'
          'uncertainReturn'
          'neverResided'
          'military'
          'milSpouse'
        These values ceom from components/FormVoterClassRadioButton.vue

        I included:
          'civilian'
          'all-voters'
        because they are valid values from the Butter database.

        from state voting rules do something like...
          if (this.stateRules.militaryseparate) {
            // use "civilian" or "military"
          }

          if (this.stateRules.votertypesused) {
            // use specific civilian and military types
          }
        TO DO:
          (1) synchronize the variables voterClass and voterType
          (2) synchronize the variable values among Butter and the source code components and pages
              e.g. synchronize voterClass === 'intendtoreturn' with voterType === 'civilian-intend-to-return' 

        BE CAREFUL:
          (1) for states that just distinuish between generic civilian and generic military
              we need voterType === 'civilian' and voterType === 'military'
              BUT
              /pages/request/voting-information "Which voter category best describes you?"
              does not have generic "Civilian" and "Military" buttons

              The code that assigns category may have to change the default values of the buttons 
              into generic values.

          (2) ? Should we change the button "Military - Active Duty" to return "military-active-duty"
              rather than "military" as it does now ?

        IN Butter:
          'request.voterClass.all-voters': butterDictionary.I46,
          'request.voterClass.allVoters': butterDictionary.I46,

          'request.voterClass.civilian': butterDictionary.C18,
          'request.voterClass.civilianType': butterDictionary.C18,
          'request.voterClass.civilian-intend-to-return': butterDictionary.Q08,

          'request.voterClass.uncertainReturn': butterDictionary.H36,
          'request.voterClass.civilian-uncertain-return': butterDictionary.Q09,

          'request.voterClass.neverResided': butterDictionary.H33,
          'request.voterClass.civilian-never-resided-in-us': butterDictionary.Q10,

          'request.voterClass.military': butterDictionary.H30,
          'request.voterClass.military-active-duty': butterDictionary.C19,
          'request.voterClass.military-dependent': butterDictionary.H32,
          'request.voterClass.military-spouse': butterDictionary.C20,
          'request.voterClass.militaryType': butterDictionary.H31,
          'request.voterClass.milSpouse': butterDictionary.H32,
      */
      let vt
      if (this.voterClass) {
        let vc = this.voterClass.toLowerCase()
        switch (vc) {
          case 'intendtoreturn':
            vt ='civilian-intend-to-return';
            break;
          case 'uncertainreturn':
            vt ='civilian-uncertain-return';
            break;
          case 'neverresided':
            vt ='civilian-never-resided-in-us';
            break;
          case 'military':
            vt='military-active-duty';
            break;
          case 'milspouse':
            vt='military-spouse';
            break;
          default:
            vt = 'civilian-uncertain-return';
        }
      } else {
        vt = 'all-voters' // give vt a non-empty value
      }

      /**
       * accommodating legacy logic for voterClass and state voting rule militaryseparate
       * I want to eliminate this code.  see TO DO.
       */
      /**
       * possible incompatibility?
       * both "militaryseparate" and "votertypesused" are selected
       * which one takes priority?
       */
      /*
      if (this.stateRules.militaryseparate && vt.includes('civilian')) {
        // collapse all specific civilian statuses into one generic type 'civilian'
        vt = 'civilian'
      }
      if (this.stateRules.militaryseparate && vt.includes('military')) {
        // revert to original military voterClass
        vt = this.voterClass.toLowerCase()
      }
      */

      /*
      if (this.stateRules.militaryseparate) {
        if (this.voterClass) {
          if (vt.includes('civilian')) {
            // collapse all specific civilian statuses into one generic type 'civilian'
            vt = 'civilian'
          }
        
          if (vt.includes('military')) {
            // revert to original military voterClass
            vt = this.voterClass.toLowerCase()
          }
        }
      }
      */
      /*
      if (!this.stateRules.votertypesused) {
        if (this.voterClass) {
          if (vt.includes('civilian')) {
            // collapse all specific civilian statuses into one generic type 'civilian'
            vt = 'civilian'
          }
        
          if (vt.includes('military')) {
            // revert to original military voterClass
            vt = this.voterClass.toLowerCase()
          }
        }
      }
      */

      let mil = this.stateRules.militaryseparate
      let vtu = this.stateRules.votertypesused

      if (mil) {
        switch (vtu) {
          case 'civilian-only':
            // incompatible combination of mil and vtu; keep previously calculated vt
            break;

          case 'military-only':
            if (vt.includes('civilian')) {
              // collapse all specific civilian statuses into one generic type 'civilian'
              vt = 'civilian'
            }
            break;

          case 'civilian-and-military':
            // incompatible combination of mil and vtu; keep previously calculated vt
            break;
            
          default:
            if (vt.includes('civilian')) {
              // collapse all specific civilian statuses into one generic type 'civilian'
              vt = 'civilian'
            }
        }
      } else {
        switch (vtu) {
          case 'civilian-only':
            // keep previously calculated vt
            break;

          case 'military-only':
            if (vt.includes('civilian')) {
              // collapse all specific civilian statuses into one generic type 'civilian'
              vt = 'civilian'
            }
            break;

          case 'civilian-and-military':
            // keep previously calculated vt
            break;
            
          default:
            if (vt.includes('civilian')) {
              // collapse all specific civilian statuses into one generic type 'civilian'
              vt = 'civilian'
            }
        }
      }

      return (this.voterClass) ? vt : this.$store.state.voterType
    },
    voterIsRegistered () {
      return this.isRegistered ? this.isRegistered : this.$store.state.voterIsRegistered
    },
    chosenState () {
      return this.votState ? this.votState : (this.selectedState ? this.selectedState : '')
    },
    stateRules () {
      let scr1
      if (this.votAdr && this.votAdr.S) {
        scr1 = this.butterStateVotingRules.find(item => item.stateid.toLowerCase().slice(0, 2) === this.votAdr.S.toLowerCase())
      } else if (this.$store.state.selectedState) {
        scr1 = this.butterStateVotingRules.find(item => item.stateid.toLowerCase().slice(0, 2) === this.$store.state.selectedState.toLowerCase())
      } else {
        scr1 = null
      }
      
      if (scr1) {
        return getStateRules(scr1, this.lang, this.$store.state.showDictionaryKeys)
      } else {
        return undefined
      }
    },
    elections () {
      if (!this.chosenState) {
        return []
      }
      const scr0 = this.butterStateElections
      if (scr0) {
        const scr1 = scr0.filter(item => item.stateid.toLowerCase().slice(0, 2) === this.chosenState.toLowerCase())
        return getElections(scr1, this.FWAB_TRIGGER_DEFAULT, this.lang)
      } else {
        return []
      }
    },
    partyInfo () {
      let pinfo = this.stateRules.partyinformation.trim()
      return pinfo ? getDictWP(pinfo, {"state": getDictFLJ(`states.${this.chosenState}`, this.dict)}) : this.dict.F23
    },
    upcomingElections () {
      return getUpcomingElections (this.elections)
    },
    deadlineElection () {
      /** 2022-10-24 John Yee
       * override per VFA call:
       *  ignore voter's registration status
       *  use the last election because the deadline election is election day
       */
      return getLastElection (this.upcomingElections)
    },
    deadlines () {
      return getDeadlines (this.deadlineElection, this.voterType, this.voterIsRegistered)
    },
    daysToElection () {
      return this.deadlines.deadlineElection.daysToElection
    },
    dict () {
      if (this.butterDictionary) {
        return getDictionary(this.butterDictionary, this.lang, 'd', this.$store.state.showDictionaryKeys)
      } else {
        return { "ERROR": "error"}
      }
    },
    phonesFromStore () {
      let pFS = Object.assign({}, this.$store.state.phones, {"TSphonesFromStore": getNewVfaDate().toISOString()})
      return pFS
    },
    allButLastIdType () {
      if (!this.idOptions || this.idOptions.length === 0) return ''
      return this.idOptions.length > 2
        ? this.idOptions.slice(0, this.idOptions.length - 1).map(x => this.getDictFLJ(`request.id.${x}`, this.dict)).join(', ')
        : this.getDictFLJ(`request.id.${this.idOptions[0]}`, this.dict)
    },
    lastIdType () {
      return this.idOptions.length > 0 ? this.getDictFLJ(`request.id.${this.idOptions.slice(-1)[0]}`, this.dict) : ''
    },
    stage () {
      switch (this.$route.params.stage) {
        case 'your-information':
          return {order: 1, name: 'Your Information', slug: this.$route.params.stage}
        case 'voting-information':
          return {order: 2, name: 'Voting Information', slug: this.$route.params.stage}
        case 'id-and-contact-information':
          return {order: 3, name: 'ID & Contact Information', slug: this.$route.params.stage}
        default:
          return {order: 4, name: 'unknown stage', slug: this.$route.params.stage}
      }
    },
    user: function () {
      return this.$store.state.userdata.user
    },
    email: {
      get () { return this.getCurrent.email || null },
      set (value) { this.update({ email: value || null }) }
    },
    salutation: {
      get () { return this.getCurrent.salutation || null },
      set (value) { this.update({ salutation: value }) }
    },
    firstName: {
      get () { return this.getCurrent.firstName || null },
      set (value) { this.update({ firstName: value.trim().replace(/\s+/g, ' ')}) }
    },
    middleName: {
      get () { return this.getCurrent.middleName || null },
      set (value) { this.update({ middleName: value.trim().replace(/\s+/g, ' ')}) }
    },
    lastName: {
      get () { return this.getCurrent.lastName || null },
      set (value) { this.update({ lastName: value.trim().replace(/\s+/g, ' ')}) }
    },
    previousName: {
      get () { return this.getCurrent.previousName || null },
      set (value) { this.update({ previousName: value }) }
    },
    suffix: {
      get () { return this.getCurrent.suffix || null },
      set (value) { this.update({ suffix: value }) }
    },
    dob: {
      get () { return this.getCurrent.dob || null },
      set (val) { this.update({ dob: val }) }
    },
    tel: {
      get () { return this.getCurrent.tel || '' },
      set (value) { this.update({ tel: value }) }
    },
    votAdr: {
      get () { return this.getCurrent.votAdr || null },
      set (value) { this.update({ votAdr: value }) }
    },
    jurisdiction () { return this.getCurrent.leo || null },
    abrAdr: {
      get () {
        if ((!this.getCurrent.abrAdr) && this.user && this.user.country) {
          return {countryiso: this.user.country}
        } else return this.getCurrent.abrAdr || null
      },
      set (value) { this.update({ abrAdr: value }) }
    },
    voterClass: {
      get () { return this.getCurrent.voterClass || null },
      set (value) { this.update({ voterClass: value }) }
    },
    identification: {
      get () { return this.getCurrent.identification || {noId: false, ssn: null, ssn4: null, ssn9: null, stateId: null} },
      set (value) { this.update({ identification: value }) }
    },
    ssn: {
      get () { return this.getCurrent.ssn || null },
      set (value) { this.update({ ssn: value }) }
    },
    sex: {
      get () { return this.getCurrent.sex || null },
      set (value) { this.update({ sex: value }) }
    },
    party: {
      get () { return this.getCurrent.party || null },
      set (value) { this.update({ party: value }) }
    },
    fax: {
      get () { return this.getCurrent.fax || null },
      set (value) { this.update({ fax: value }) }
    },
    altEmail: {
      get () { return this.getCurrent.altEmail || null },
      set (value) { this.update({ altEmail: value }) }
    },
    isRegistered: {
      get () { return this.getCurrent.isRegistered || null },
      set (value) { this.update({ isRegistered: value }) }
    },
    recBallot: {
      get () { return this.getCurrent.recBallot || null },
      set (value) { this.update({ recBallot: value }) }
    },
    stateId: {
      get () { return this.getCurrent.stateId || null },
      set (value) { this.update({ stateId: value }) }
    },
    fwdAdr: {
      get () { return this.getCurrent.fwdAdr || null },
      set (value) { this.update({ fwdAdr: value }) }
    },
    addlInfo: {
      get () { return this.getCurrent.addlInfo || null },
      set (value) { this.update({ addlInfo: value }) }
    },
    stateSpecial: {
      get () { return this.getCurrent.stateSpecial || null },
      set (value) { this.update({ stateSpecial: value }) }
    },
    joinDa: {
      get () { return this.getCurrent.joinDa },
      set (value) { this.update({ joinDa: value }) }
    },
    DaFirstName: {
      get () { return this.getCurrent.DaFirstName || null },
      set (value) { this.update({ DaFirstName: value.trim().replace(/\s+/g, ' ') }) }
    },
    DaMiddleName: {
      get () { return this.getCurrent.DaMiddleName || null },
      set (value) { this.update({ DaMiddleName: value.trim().replace(/\s+/g, ' ') }) }
    },
    DaLastName: {
      get () { return this.getCurrent.DaLastName || null },
      set (value) { this.update({ DaLastName: value.trim().replace(/\s+/g, ' ') }) }
    },
    DaEmail: {
      get () { return this.getCurrent.DaEmail || null },
      set (value) { this.update({ DaEmail: value }) }
    },
    DaPhone: {
      get () { return this.getCurrent.DaPhone || null },
      set (value) { this.update({ DaPhone: value || null }) }
    },
    DaMobile: {
      get () { return this.getCurrent.DaMobile || null },
      set (value) { this.update({ DaMobile: value || null }) }
    },
    DaOptinEmail: {
      get () { return this.getCurrent.email_opt_in },
      set (value) { this.update({ email_opt_in: value }) }
    },
    DaOptinMobile: {
      get () { return this.getCurrent.mobile_opt_in },
      set (value) { this.update({ mobile_opt_in: value }) }
    },
    DaOptinPhone: {
      get () { return this.getCurrent.phone_opt_in },
      set (value) { this.update({ phone_opt_in: value }) }
    },
    optInVoterReminder: {
      get () { return this.getCurrent.optInVoterReminder || false },
      set (value) { this.update({ optInVoterReminder: value }) }
    },
    isExistingMember () {
      return this.getCurrent.democratsAbroad ? this.getCurrent.democratsAbroad.isExistingMember : null
    },
    /*
    isJoiningJoinedDa () {
      return this.isRequestingToJoin || this.isRequestingGPPBallot
    },
    */
    isRequestingToJoin () {
      return this.getCurrent.democratsAbroad ? this.getCurrent.democratsAbroad.isRequestingToJoin : null
    },
    isRequestingGPPBallot () {
      return this.getCurrent.democratsAbroad ? this.getCurrent.democratsAbroad.isRequestingGPPBallot : null
    },
    DAmember () {
      if (this.getCurrent.democratsAbroad !== undefined) {
        return { isExistingMember: this.getCurrent.democratsAbroad.isExistingMember,
                isRequestingToJoin: this.getCurrent.democratsAbroad.isRequestingToJoin
               }
      } else {
        return { isExistingMember: null,
                isRequestingToJoin: null
               }
      }
    },
    isHoveredVoterClass () {
      return !!this.hoveredVoterClass
    },
    hoveredVoterClassLabel () {
      return ({"intendToReturn" : this.dict.P01,
               "uncertainReturn" : this.dict.P02,
               "neverResided" : this.dict.P03,
               "military" : this.dict.P04,
               "milSpouse" : this.dict.P05,
              })[this.hoveredVoterClass]
    },
    hoveredVoterClassStatusInfo () {
      let tip
      if (!this.stateRules || !this.hoveredVoterClass) {
        tip = this.dict.H34
      } else {
        tip = ({"uncertainReturn" : this.stateRules.overseas_status_uncertain_return,
                "intendToReturn" : this.stateRules.overseas_status_intend_to_return,
                "neverResided" : this.stateRules.overseas_status_never_resided_in_us,
                "military" : this.stateRules.overseas_status_military_active_duty,
                "milSpouse" : this.stateRules.overseas_status_military_spouse
               })[this.hoveredVoterClass]
      }
      tip = tip.replace(/\\n/gi, '<br>').replace(/\\/gi, '')
      return getDictWP(tip, {"state": getDictFLJ(`states.${this.chosenState}`, this.dict)})
    },
    OverseasStatusInfoTip () {
      let tip
      if (!this.stateRules || !this.voterClass) {
        tip = this.dict.H34
      } else {
        tip = ({"uncertainReturn" : this.stateRules.overseas_status_uncertain_return,
                "intendToReturn" : this.stateRules.overseas_status_intend_to_return,
                "neverResided" : this.stateRules.overseas_status_never_resided_in_us,
                "military" : this.stateRules.overseas_status_military_active_duty,
                "milSpouse" : this.stateRules.overseas_status_military_spouse
        })[this.voterClass]
      }
      tip = tip.replace(/\\n/gi, '<br>').replace(/\\/gi, '')
      return getDictWP(tip, {"state": getDictFLJ(`states.${this.chosenState}`, this.dict)})
    },
    VFAParameters () {
      return getVFAParameters(this.$store)
    },
    MAXLENGTH_FIELDS () {
      return this.VFAParameters.MAXLENGTH_FIELDS
    },
    FWAB_TRIGGER_DEFAULT () {
      return this.VFAParameters.FWAB_TRIGGER_DEFAULT
    },
    butterLeos () {
      return this.$store.state.butterLeos
    },
    butterStateElections () {
      return this.$store.state.butterStateElections
    },
    butterStateVotingRules () {
      return this.$store.state.butterStateVotingRules
    },
    butterDictionary () {
      return this.$store.state.butterDictionary
    },
    ...mapGetters('data', ['isValidNumber']),
    ...mapGetters('requests', ['getCurrent']),
    ...mapState('data', ['postal']),
    ...mapState(['isPrivacyOptInModalActive'])
  },
  watch: {
    /** todo: 2024-01-07 John Yee do we really need this "watch: tel"?  It does nothing.  What was it supposed to do? */
    tel (value) {
      // console.log('tel', value)
    },
  },
  methods: {
    /**
     * saveCookieConsent()
     * 2023-06-21 John Yee
     *
     * VFA wants to record the cookie consent options a visitor makes.
     *
     * My idea is to put the consent options in the currentRequest object because that is sent
     * to the backend database.  I wanted to save the consent options when the visitor clicked
     * "I agree" on the privacy modal screen.  However, the currentRequest object wasn't created
     * yet and I couldn't find the code that creates it.
     *
     * Heather Piwowar noted that we only send FPCA data to the database; we don't really need to
     * log the consent for all visitors.  On that idea I save the consent options from a place
     * that is sure to have the currentRequest object.  One such place is - the request page.
     *
     * If the visitor clicks on the "Next" button, we are reasonably sure the visitor is filling
     * out an FPCA.  So, I call the saveCookieConsent() method when the visitor clicks the "Next"
     * button at the end of step 1.
     *
     * I know - a bit kludgy.
     */
    saveCookieConsent() {
      const cookieConsent = this.$store.state.cookieConsent
      this.$store.commit('requests/update', { cookies: cookieConsent })
    },
    isStateRulesSpecialInputTriggered (stateRulesSpecialInput, registrationStatus, abroadStatus, partyAffiliation, identificationStatus) {
      const NOTUSEDASATRIGGER = 'not-used-as-a-trigger'
      const PARTYTRIGGERVALUES = [ 'not-used-as-a-trigger', 'democratic', 'republican', 'none', 'other' ]

      let trig = true

      if (stateRulesSpecialInput.registration_trigger &&
          stateRulesSpecialInput.registration_trigger!==NOTUSEDASATRIGGER)
      {
        trig = trig && (stateRulesSpecialInput.registration_trigger.split('-or-').indexOf(registrationStatus.toLowerCase()) >-1)
      }

      if (stateRulesSpecialInput.abroad_trigger &&
          stateRulesSpecialInput.abroad_trigger!==NOTUSEDASATRIGGER &&
          abroadStatus)
      {
        trig = trig && (stateRulesSpecialInput.abroad_trigger.split('-or-').indexOf(abroadStatus.toLowerCase()) >-1)
      }

      if (stateRulesSpecialInput.party_trigger &&
          stateRulesSpecialInput.party_trigger!==NOTUSEDASATRIGGER)
      {
        let party = partyAffiliation
        if (party) {
          party = party.toLowerCase()

          // if the voter selects "Other", then the partyAffiliation becomes the name of the party.
          // that is, the partyAffiliation does not remain "Other"
          // in this case we set party to "other" so party is among the valid trigger values in stateRules.specialinput[].party_trigger
          party = (PARTYTRIGGERVALUES.indexOf(party)<0) ? "other" : party
        }

        trig = trig && (stateRulesSpecialInput.party_trigger.split('-or-').indexOf(party) >-1)
      }

      if (stateRulesSpecialInput.identification_trigger &&
          stateRulesSpecialInput.identification_trigger!==NOTUSEDASATRIGGER)
      {
        trig = trig && (stateRulesSpecialInput.identification_trigger.split('-or-').indexOf(identificationStatus.toLowerCase())>-1)
      }

      return trig
    },
    optIn () {
      this.$cookie.set('vfaOptIn', true, 1)
      this.optedIn = true
      this.togglePrivacyModalActiveState(false)
    },
    focusFirstErrorOrAdvance (nextPage) {
      switch (this.$route.params.stage) {
        case 'your-information':
          this.$v.salutation.$touch()
          this.$v.firstName.$touch()
          this.$v.lastName.$touch()
          this.$v.previousName.$touch()
          this.$v.email.$touch()
          this.$v.tel.$touch()
          this.$v.abrAdr.$touch()
          this.$v.abrAdr.A.$touch()
          this.$v.abrAdr.countryiso.$touch()
          break
        case 'voting-information':
          this.$v.votAdr.$touch()
          this.$v.votAdr.A.$touch()
          this.$v.votAdr.C.$touch()
          this.$v.votAdr.Z.$touch()
          this.$v.jurisdiction.$touch()
          this.$v.voterClass.$touch()
          this.$v.isRegistered.$touch()
          this.$v.recBallot.$touch()
          this.$v.fax.$touch()
          this.$v.altEmail.$touch()
          this.$v.fwdAdr.$touch()
          this.$v.fwdAdr.A.$touch()
          this.$v.email.$touch()
          this.skippedEmail = false
          break
        case 'id-and-contact-information':
          this.$v.sex.$touch()
          this.$v.dob.$touch()
          this.$v.party.$touch()
          this.$v.DaGlobalPresidentialPrimary.$touch()
          this.$v.DaFirstName.$touch()
          this.$v.DaMiddleName.$touch()
          this.$v.DaLastName.$touch()
          this.$v.DaEmail.$touch()
          this.$v.DaPhone.$touch()
          this.$v.DaMobile.$touch()
          this.$v.DaOptinEmail.$touch()
          this.$v.DaOptinMobile.$touch()
          this.$v.DaOptinPhone.$touch()
          this.$v.stateSpecial.$touch()
          this.$v.identification.$touch()
          break
      }

      switch (true) {
        case this.stage.slug === 'your-information' && this.$v.firstName.$error:
          this.$refs.firstName.$el.scrollIntoView()
          this.$refs.firstName.focus()
          this.$store.dispatch('requests/recordAnalytics', { event: 'Form Error', attributes: { field: 'firstName' } })
          break
        case this.stage.slug === 'your-information' && this.$v.lastName.$error:
          this.$refs.lastName.$el.scrollIntoView()
          this.$refs.lastName.focus()
          this.$store.dispatch('requests/recordAnalytics', { event: 'Form Error', attributes: { field: 'lastName' } })
          break
        case this.stage.slug === 'your-information' && this.$v.previousName.$error:
          this.$refs.previousName.$el.scrollIntoView()
          this.$refs.previousName.$el.querySelector('input').focus()
          this.$store.dispatch('requests/recordAnalytics', { event: 'Form Error', attributes: { field: 'previousName' } })
          break
        case this.stage.slug === 'your-information' && this.$v.email.$error:
          this.$refs.email.$el.scrollIntoView()
          this.$refs.email.$el.querySelector('input').focus()
          this.$store.dispatch('requests/recordAnalytics', {event: 'Form Error', attributes: {field: 'email'}})
          break
        case this.stage.slug === 'your-information' && this.$v.abrAdr.countryiso && this.$v.abrAdr.countryiso.$error:
          this.$refs.abrAdr.$el.scrollIntoView()
          this.$refs.abrAdr.$el.focus()
          break
        case this.stage.slug === 'your-information' && this.$v.abrAdr.A && this.$v.abrAdr.A.$error && !this.abrAdr.usesAlternateFormat:
          this.$refs.abrAdr.$el.scrollIntoView()
          // this.$refs.abrAdr.$refs.A[0].$el.scrollIntoView()
          this.$refs.abrAdr.$refs.A[0].focus()
          this.$store.dispatch('requests/recordAnalytics', {event: 'Form Error', attributes: {field: 'abrAdr.A'}})
          break
        case this.stage.slug === 'your-information' && this.$v.abrAdr.B && this.$v.abrAdr.B.$error && !this.abrAdr.usesAlternateFormat:
          this.$refs.abrAdr.$refs.B[0].$el.scrollIntoView()
          this.$refs.abrAdr.$refs.B[0].focus()
          this.$store.dispatch('requests/recordAnalytics', {event: 'Form Error', attributes: {field: 'abrAdr.B'}})
          break
        case this.stage.slug === 'your-information' && this.$v.abrAdr.C && this.$v.abrAdr.C.$error && !this.abrAdr.usesAlternateFormat:
          this.$refs.abrAdr.$refs.C[0].$el.scrollIntoView()
          this.$refs.abrAdr.$refs.C[0].focus()
          this.$store.dispatch('requests/recordAnalytics', {event: 'Form Error', attributes: {field: 'abrAdr.C'}})
          break
        case this.stage.slug === 'your-information' && this.$v.abrAdr.D && this.$v.abrAdr.D.$error && !this.abrAdr.usesAlternateFormat:
          this.$refs.abrAdr.$refs.D[0].$el.scrollIntoView()
          this.$refs.abrAdr.$refs.D[0].focus()
          this.$store.dispatch('requests/recordAnalytics', {event: 'Form Error', attributes: {field: 'abrAdr.D'}})
          break
        case this.stage.slug === 'your-information' && this.$v.abrAdr.S && this.$v.abrAdr.S.$error && !this.abrAdr.usesAlternateFormat:
          this.$refs.abrAdr.$refs.S[0].$el.scrollIntoView()
          this.$refs.abrAdr.$refs.S[0].focus()
          this.$store.dispatch('requests/recordAnalytics', {event: 'Form Error', attributes: {field: 'abrAdr.S'}})
          break
        case this.stage.slug === 'your-information' && this.$v.abrAdr.X && this.$v.abrAdr.X.$error && !this.abrAdr.usesAlternateFormat:
          this.$refs.abrAdr.$refs.X[0].$el.scrollIntoView()
          this.$refs.abrAdr.$refs.X[0].focus()
          this.$store.dispatch('requests/recordAnalytics', {event: 'Form Error', attributes: {field: 'abrAdr.X'}})
          break
        case this.stage.slug === 'your-information' && this.$v.abrAdr.Y && this.$v.abrAdr.Y.$error && !this.abrAdr.usesAlternateFormat:
          this.$refs.abrAdr.$refs.Y[0].$el.scrollIntoView()
          this.$refs.abrAdr.$refs.Y[0].focus()
          this.$store.dispatch('requests/recordAnalytics', {event: 'Form Error', attributes: {field: 'abrAdr.Y'}})
          break
        case this.stage.slug === 'your-information' && this.$v.abrAdr.Z && this.$v.abrAdr.Z.$error && !this.abrAdr.usesAlternateFormat:
          this.$refs.abrAdr.$refs.Z[0].$el.scrollIntoView()
          this.$refs.abrAdr.$refs.Z[0].focus()
          this.$store.dispatch('requests/recordAnalytics', {event: 'Form Error', attributes: {field: 'abrAdr.Z'}})
          break
        case this.stage.slug === 'your-information' && this.$v.abrAdr.alt1 && this.$v.abrAdr.alt1.$error && this.abrAdr.usesAlternateFormat:
          this.$refs.abrAdr.$refs.alt1[0].$el.scrollIntoView()
          this.$refs.abrAdr.$refs.alt1[0].focus()
          this.$store.dispatch('requests/recordAnalytics', {event: 'Form Error', attributes: {field: 'abrAdr.alt1'}})
          break
        case this.stage.slug === 'your-information' && this.$v.tel.$error:
          this.$refs.tel.$el.scrollIntoView()
          this.$refs.tel.$el.querySelector('input').focus()
          this.$store.dispatch('requests/recordAnalytics', { event: 'Form Error', attributes: { field: 'tel' } })
          break
        case this.stage.slug === 'voting-information' && this.$v.votAdr.A.$error:
          this.$refs.votAdr.$el.scrollIntoView()
          this.$refs.votAdr.$refs.A.focus()
          this.$store.dispatch('requests/recordAnalytics', {event: 'Form Error', attributes: {field: 'votAdr.A'}})
          break
        case this.stage.slug === 'voting-information' && this.$v.votAdr.C.$error:
          this.$refs.votAdr.$el.scrollIntoView()
          this.$refs.votAdr.$refs.C.focus()
          this.$store.dispatch('requests/recordAnalytics', {event: 'Form Error', attributes: {field: 'votAdr.C'}})
          break
        case this.stage.slug === 'voting-information' && this.$v.votAdr.S.$error:
          this.$refs.votAdr.$refs.S.$el.scrollIntoView()
          this.$refs.votAdr.$refs.S.focus()
          this.$store.dispatch('requests/recordAnalytics', {event: 'Form Error', attributes: {field: 'votAdr.S'}})
          break
        case this.stage.slug === 'voting-information' && this.$v.votAdr.Z.$error:
          this.$refs.votAdr.$refs.Z.$el.scrollIntoView()
          this.$refs.votAdr.$refs.Z.focus()
          this.$store.dispatch('requests/recordAnalytics', {event: 'Form Error', attributes: {field: 'votAdr.Z'}})
          break
        case this.stage.slug === 'voting-information' && this.$v.jurisdiction.$error:
          this.$refs.jurisdiction.$el.scrollIntoView()
          this.$refs.jurisdiction.$el.focus()
          this.$store.dispatch('requests/recordAnalytics', {event: 'Form Error', attributes: {field: 'jurisdiction'}})
          break
        case this.stage.slug === 'voting-information' && this.$v.fax.$error:
          this.$refs.fax.$el.scrollIntoView()
          this.$refs.fax.$el.querySelector('input').focus()
          this.$store.dispatch('requests/recordAnalytics', {event: 'Form Error', attributes: {field: 'fax'}})
          break
        case this.stage.slug === 'voting-information' && this.$v.voterClass.$error:
          this.$refs.voterClass.$el.scrollIntoView()
          this.$store.dispatch('requests/recordAnalytics', {event: 'Form Error', attributes: {field: 'voterClass'}})
          break
        case this.stage.slug === 'voting-information' && this.$v.isRegistered.$error:
          this.$refs.isRegistered.$el.scrollIntoView()
          this.$store.dispatch('requests/recordAnalytics', {event: 'Form Error', attributes: {field: 'isRegistered'}})
          break
        case this.stage.slug === 'voting-information' && this.$v.recBallot.$error:
          this.$refs.recBallot.$el.scrollIntoView()
          this.$store.dispatch('requests/recordAnalytics', {event: 'Form Error', attributes: {field: 'recBallot'}})
          break
        case this.stage.slug === 'voting-information' && this.$v.email.$error && this.recBallot === 'email':
          this.$refs.email.$el.scrollIntoView()
          this.$refs.email.$el.querySelector('input').focus()
          this.$store.dispatch('requests/recordAnalytics', {event: 'Form Error', attributes: {field: 'email'}})
          break
        case this.stage.slug === 'voting-information' && this.$v.altEmail.$error && !!this.$refs.altEmail:
          this.$refs.altEmail.$el.scrollIntoView()
          this.$refs.altEmail.$el.querySelector('input').focus()
          this.$store.dispatch('requests/recordAnalytics', {event: 'Form Error', attributes: {field: 'altEmail'}})
          break
        case this.stage.slug === 'voting-information' && this.$v.fwdAdr.countryiso && this.$v.fwdAdr.countryiso.$error:
          this.$refs.fwdAdr.$el.scrollIntoView()
          this.$refs.fwdAdr.$el.focus()
          break
        case this.stage.slug === 'voting-information' && this.$v.fwdAdr.A && this.$v.fwdAdr.A.$error:
          this.$refs.fwdAdr.$el.scrollIntoView()
          // this.$refs.fwdAdr.$refs.A[0].$el.scrollIntoView()
          this.$refs.fwdAdr.$refs.A[0].focus()
          this.$store.dispatch('requests/recordAnalytics', {event: 'Form Error', attributes: {field: 'fwdAdr.A'}})
          break
        case this.stage.slug === 'voting-information' && this.$v.fwdAdr.B && this.$v.fwdAdr.B.$error:
          this.$refs.fwdAdr.$refs.B[0].$el.scrollIntoView()
          this.$refs.fwdAdr.$refs.B[0].focus()
          this.$store.dispatch('requests/recordAnalytics', {event: 'Form Error', attributes: {field: 'fwdAdr.B'}})
          break
        case this.stage.slug === 'voting-information' && this.$v.fwdAdr.C && this.$v.fwdAdr.C.$error:
          this.$refs.fwdAdr.$refs.C[0].$el.scrollIntoView()
          this.$refs.fwdAdr.$refs.C[0].focus()
          this.$store.dispatch('requests/recordAnalytics', {event: 'Form Error', attributes: {field: 'fwdAdr.C'}})
          break
        case this.stage.slug === 'voting-information' && this.$v.fwdAdr.D && this.$v.fwdAdr.D.$error:
          this.$refs.fwdAdr.$refs.D[0].$el.scrollIntoView()
          this.$refs.fwdAdr.$refs.D[0].focus()
          this.$store.dispatch('requests/recordAnalytics', {event: 'Form Error', attributes: {field: 'fwdAdr.D'}})
          break
        case this.stage.slug === 'voting-information' && this.$v.fwdAdr.S && this.$v.fwdAdr.S.$error:
          this.$refs.fwdAdr.$refs.S[0].$el.scrollIntoView()
          this.$refs.fwdAdr.$refs.S[0].focus()
          this.$store.dispatch('requests/recordAnalytics', {event: 'Form Error', attributes: {field: 'fwdAdr.S'}})
          break
        case this.stage.slug === 'voting-information' && this.$v.fwdAdr.X && this.$v.fwdAdr.X.$error:
          this.$refs.fwdAdr.$refs.X[0].$el.scrollIntoView()
          this.$refs.fwdAdr.$refs.X[0].focus()
          this.$store.dispatch('requests/recordAnalytics', {event: 'Form Error', attributes: {field: 'fwdAdr.X'}})
          break
        case this.stage.slug === 'voting-information' && this.$v.fwdAdr.Y && this.$v.fwdAdr.Y.$error:
          this.$refs.fwdAdr.$refs.Y[0].$el.scrollIntoView()
          this.$refs.fwdAdr.$refs.Y[0].focus()
          this.$store.dispatch('requests/recordAnalytics', {event: 'Form Error', attributes: {field: 'fwdAdr.Y'}})
          break
        case this.stage.slug === 'voting-information' && this.$v.fwdAdr.Z && this.$v.fwdAdr.Z.$error:
          this.$refs.fwdAdr.$refs.Z[0].$el.scrollIntoView()
          this.$refs.fwdAdr.$refs.Z[0].focus()
          this.$store.dispatch('requests/recordAnalytics', {event: 'Form Error', attributes: {field: 'fwdAdr.Z'}})
          break
        case this.stage.slug === 'id-and-contact-information' && this.$v.dob.$error:
          // 2023-04-29 John Yee. The dob input box is a legacy element; but I still need it for validation.
          this.$refs.dob.$el.scrollIntoView()
          // this.$refs.dob.$el.querySelector('input').focus() // do not focus because the input control is hidden
          this.$store.dispatch('requests/recordAnalytics', {event: 'Form Error', attributes: {field: 'dob'}})
          break
        case this.stage.slug === 'id-and-contact-information' && this.$v.sex.$error:
          this.$refs.sex.$el.scrollIntoView()
          this.$store.dispatch('requests/recordAnalytics', {event: 'Form Error', attributes: {field: 'sex'}})
          break
        case this.stage.slug === 'id-and-contact-information' && this.$v.party.$error:
          this.$refs.party.$el.scrollIntoView()
          this.$store.dispatch('requests/recordAnalytics', {event: 'Form Error', attributes: {field: 'party'}})
          break
        case this.stage.slug === 'id-and-contact-information' && this.isGPPRegistrationOpen && this.$v.DaGlobalPresidentialPrimary.$error:
          this.$refs.DaGlobalPresidentialPrimary.$el.scrollIntoView()
          // this.$store.dispatch('requests/recordAnalytics', {event: 'Form Error', attributes: {field: 'DaGlobalPresidentialPrimary'}})
          break

        /**
         * 2023-06-15 John Yee
         * DaPhone, and DaMobile
         * require the boolean (this.getCurrent.*_opt_in ? this.getCurrent.*_opt_in : false)
         *
         * The main code suppresses the input box for these fields if the voter does not select to opt-in.
         * When that happens the coresponding component is not created and this.$refs.* is undefined,
         * which would cause the statements inside the case statement to throw an error.
         */
        case this.stage.slug === 'id-and-contact-information' && this.$v.DaFirstName.$error && (this.isExistingMember || this.isRequestingToJoin):
          this.$refs.DaFirstName.$el.scrollIntoView()
          this.$refs.DaFirstName.$el.querySelector('input').focus()
          break
        case this.stage.slug === 'id-and-contact-information' && this.$v.DaMiddleName.$error && (this.isExistingMember || this.isRequestingToJoin):
          this.$refs.DaMiddleName.$el.scrollIntoView()
          this.$refs.DaMiddleName.$el.querySelector('input').focus()
          break
        case this.stage.slug === 'id-and-contact-information' && this.$v.DaLastName.$error && (this.isExistingMember || this.isRequestingToJoin):
          this.$refs.DaLastName.$el.scrollIntoView()
          this.$refs.DaLastName.$el.querySelector('input').focus()
          break
        case this.stage.slug === 'id-and-contact-information' && this.$v.DaEmail.$error && (this.isExistingMember || this.isRequestingToJoin):
          this.$refs.DaEmail.$el.scrollIntoView()
          this.$refs.DaEmail.$el.querySelector('input').focus()
          break
        case this.stage.slug === 'id-and-contact-information' && this.$v.DaOptinEmail.$error && (this.isJoiningJoinedDa === true || this.isJoiningJoinedDa === 'already a member'):
          this.$refs.DaOptinEmail.$el.scrollIntoView()
          break
        case this.stage.slug === 'id-and-contact-information' && this.$v.DaPhone.$error && this.isJoiningJoinedDa === true && (this.getCurrent.phone_opt_in ? this.getCurrent.phone_opt_in : false):
          this.$refs.DaPhone.$el.scrollIntoView()
          this.$refs.DaPhone.$el.querySelector('input').focus()
          break
        case this.stage.slug === 'id-and-contact-information' && this.$v.DaOptinPhone.$error && (this.isJoiningJoinedDa === true || this.isJoiningJoinedDa === 'already a member'):
          this.$refs.DaOptinPhone.$el.scrollIntoView()
          break
        case this.stage.slug === 'id-and-contact-information' && this.$v.DaMobile.$error && this.isJoiningJoinedDa === true && (this.getCurrent.mobile_opt_in ? this.getCurrent.mobile_opt_in : false):
          this.$refs.DaMobile.$el.scrollIntoView()
          this.$refs.DaMobile.$el.querySelector('input').focus()
          break
        case this.stage.slug === 'id-and-contact-information' && this.$v.DaOptinMobile.$error && (this.isJoiningJoinedDa === true || this.isJoiningJoinedDa === 'already a member'):
          this.$refs.DaOptinMobile.$el.scrollIntoView()
          break

        case this.stage.slug === 'id-and-contact-information' && this.$v.stateSpecial.$error:
          this.$refs.stateSpecial.$el.scrollIntoView()
          this.$store.dispatch('requests/recordAnalytics', {event: 'Form Error', attributes: {field: 'sex'}})
          break
        case this.stage.slug === 'id-and-contact-information' && this.$v.identification.ssn.$error:
          if (this.$refs.id.$refs.ssn) this.$refs.id.$refs.ssn.focus()
          this.$store.dispatch('requests/recordAnalytics', {event: 'Form Error', attributes: {field: 'identification.ssn'}})
          break
        case this.stage.slug === 'id-and-contact-information' && this.$v.identification.ssn4.$error:
          if (this.$refs.id.$refs.ssn4) this.$refs.id.$refs.ssn4.focus()
          this.$store.dispatch('requests/recordAnalytics', {event: 'Form Error', attributes: {field: 'identification.ssn'}})
          break
        case this.stage.slug === 'id-and-contact-information' && this.$v.identification.ssn9.$error:
          if (this.$refs.id.$refs.ssn9) this.$refs.id.$refs.ssn9.focus()
          this.$store.dispatch('requests/recordAnalytics', {event: 'Form Error', attributes: {field: 'identification.ssn'}})
          break
        case this.stage.slug === 'id-and-contact-information' && this.$v.identification.stateId.$error:
          this.$refs.id.$refs.StateId.focus()
          this.$store.dispatch('requests/recordAnalytics', {event: 'Form Error', attributes: {field: 'identification.stateId'}})
          break
        default:
          this.$router.push(nextPage)
          // this.$store.dispatch('requests/updateRequest', {status: 'completed: ' + this.stage.slug})
          this.$store.dispatch('requests/recordAnalytics', {event: 'completed: ' + this.stage.slug})
      }
    },
    addressDelayTouch (type, val) {
      // console.log('type', type, 'val', val)
      if (val === 'countryiso' || (this[type].countryiso && !this.postal[this[type].countryiso])) {
        this.$store.dispatch('data/updateCountryData', this[type].countryiso)
          .then(() => {
            this.$nextTick()
              .then(() => this.delayTouch(this.$v[type][val]))
          })
      } else this.delayTouch(this.$v[type][val])
    },
    delayTouch ($v) {
      if ($v && '$reset' in $v && '$touch' in $v) {
        $v.$reset()
        if (touchMap.has($v)) {
          clearTimeout(touchMap.get($v))
        }
        touchMap.set($v, setTimeout($v.$touch, 1000))
      }
    },
    setHoveredVoterClass(val) {
      this.hoveredVoterClass = val

      /**
       * side effect:
       * Try to position the information box next to the voter category buttons
       */
      // left tile: voter category buttons
      const votClass = this.$refs['form-voter-class-radio-button']
      const votInfo = this.$refs['right-tile-voting-information']
      const vOffset = votClass.offsetTop - votInfo.offsetTop

      // right tile: information box
      const bulmaBreakpointMobile = 767
      var votClassBox = this.$refs.hoveredVoterClassBox
      votClassBox.style.marginTop = (this.getWindowSize().width > bulmaBreakpointMobile) ? parseInt(vOffset) + "px" : "5px"
    },
    setValidITINumbertel (val) {
      this.isValidNumberTel = val.isValidNumber
      this.tel = val.phoneNumber
    },
    setValidITINumberfax (val) {
      this.isValidNumberFax = val.isValidNumber
      this.fax = val.phoneNumber
    },
    setValidITINumberDaPhone (val) {
      this.isValidNumberDaPhone = val.isValidNumber
      this.DaPhone = val.phoneNumber
    },
    setValidITINumberDaMobile (val) {
      this.isValidNumberDaMobile = val.isValidNumber
      this.DaMobile = val.phoneNumber
    },

    /*
      2024-03-24 John Yee
      isExistingMember, isRequestingToJoin, and isJoiningJoinedDa are set using currentRequest
      and not from the emitted values.  This allows the voter to switch back-and-forth among
      different "pages" and not lose the join/requesting-to-join status.

      TODO: code review and cleanup.  also FormPartyInput.vue
    */
    getisExistingMember (val) {
      // this.isExistingMember=val
    },
    getisRequestingToJoin (val) {
      // this.isRequestingToJoin=val
    },
    getisJoiningJoinedDa (val) {
      this.isJoiningJoinedDa=val
    },

    getGPPRegistrationOpen (val) {
      this.isGPPRegistrationOpen=val
    },
    getIsBannedParty (val) {
      this.isBannedParty = val
    },
    getDictFLJ(dictItem, dict) {
      /**
       * 2022-12-04 John Yee
       * This construction looks weird - like a recursive call; but, it's not.
       * The "getDictFLJ(dictItem, dict)" in the return statement is really
       * the function getDictFLJ(dictItem, dict) that is imported from ~/utils/butterUtils.js
       *
       * reference: https://stackoverflow.com/questions/52332993/calling-a-function-from-a-helper-in-vue-template
       */
      return getDictFLJ(dictItem, dict)
    },
    getDictWP(dictItem, paramObj) {
      /**
       * 2022-12-04 John Yee
       * This construction looks weird - like a recursive call; but, it's not.
       * The "getDictWP(dictItem, paramObj)" in the return statement is really
       * the function getDictWP that is imported from ~/utils/butterUtils.js
       *
       * reference: https://stackoverflow.com/questions/52332993/calling-a-function-from-a-helper-in-vue-template
       */
      return getDictWP(dictItem, paramObj)
    },
    getWindowSize() {
      return { width: window.innerWidth, height: window.innerHeight }
    },
    ...mapMutations('requests', ['update']),
    ...mapMutations(['togglePrivacyModalActiveState'])
  },
  mounted () {
    if (!this.$cookie.get('vfaOptIn')) {
      this.optedIn = false
      this.togglePrivacyModalActiveState(true)
    } else {
      this.optedIn = true
      this.togglePrivacyModalActiveState(false)
    }
  },
  unmounted () {
    window.removeEventListener('resize', (e)=>this.getWindowSize())
  },
  created () {
    window.addEventListener('resize', (e)=>this.getWindowSize())
  },
  validations () {
    return {
      email: {
        required: requiredIf(function (model) { return model.recBallot === 'email' }),
        email: optionalEmail
      },
      salutation: {
        maxLength: maxLength(this.MAXLENGTH_FIELDS.salutation)
      },
      firstName: {
        required,
        maxLength: maxLength(this.MAXLENGTH_FIELDS.firstName)
      },
      middleName: {
        maxLength: maxLength(this.MAXLENGTH_FIELDS.middleName)
      },
      lastName: {
        required,
        maxLength: maxLength(this.MAXLENGTH_FIELDS.lastName)
      },
      previousName: {
        previousName: {
          required: requiredIf(function (model) { return model ? model.usesPreviousName === true : false }),
          maxLength: maxLength(this.MAXLENGTH_FIELDS.previousName)
        }
      },
      suffix: {
        maxLength: maxLength(this.MAXLENGTH_FIELDS.suffix)
      },
      abrAdr: {
        country: { },
        A: {
          required: addressPartRequired('A'),
          maxLength: maxLength(this.MAXLENGTH_FIELDS.A)
        },
        B: {
          required: addressPartRequired('B'),
          maxLength: maxLength(this.MAXLENGTH_FIELDS.B)
        },
        C: {
          required: addressPartRequired('C'),
          maxLength: maxLength(this.MAXLENGTH_FIELDS.C)
        },
        D: {
          required: addressPartRequired('D'),
          maxLength: maxLength(this.MAXLENGTH_FIELDS.D)
        },
        S: {
          required: addressPartRequired('S'),
          maxLength: maxLength(this.MAXLENGTH_FIELDS.S),
          countryState: countryState(this.abrAdr ? this.abrAdr : null)
        },
        X: {
          required: addressPartRequired('X'),
          maxLength: maxLength(this.MAXLENGTH_FIELDS.X)
        },
        Z: {
          required: addressPartRequired('Z'),
          maxLength: maxLength(this.MAXLENGTH_FIELDS.Z),
          postalCode: postalCode(this.abrAdr ? this.abrAdr : null)
        },
        countryiso: {
          required,
          maxLength: maxLength(2),
          minLength: minLength(2)
        },
        alt1: { required: requiredIf('usesAlternateFormat') },
        alt2: {},
        alt3: {},
        alt4: {},
        alt5: {},
        usesAlternateFormat: {}
      },
      voterClass: {
        required
      },
      votAdr: {
        A: {
          required,
          maxLength: maxLength(this.MAXLENGTH_FIELDS.A)
        },
        C: {
          required,
          maxLength: maxLength(this.MAXLENGTH_FIELDS.C)
        },
        S: {
          required,
          maxLength: maxLength(this.MAXLENGTH_FIELDS.S)
        },
        Y: {},
        Z: {
          required,
          usZip: usZip(this.votAdr ? this.votAdr.S : null)
        }
      },
      jurisdiction: {
        required
      },
      recBallot: {
        required
      },
      dob: {
        required
      },
      fax: {
        required: requiredIf(function (model) { return model.recBallot === 'fax' }),
        validPhone () { return this.isValidNumberFax }
      },
      tel: {
        validPhone () { return this.isValidNumberTel }
      },
      altEmail: {
        email: optionalEmail
      },
      DaFirstName: {
        required: requiredIf(function () { return (this.isExistingMember || this.isRequestingToJoin) }),
        maxLength: maxLength(this.MAXLENGTH_FIELDS.firstName)
      },
      DaMiddleName: {
        maxLength: maxLength(this.MAXLENGTH_FIELDS.middleName)
      },
      DaLastName: {
        required: requiredIf(function () { return (this.isExistingMember || this.isRequestingToJoin) }),
        maxLength: maxLength(this.MAXLENGTH_FIELDS.lastName)
      },
      DaEmail: {
        required: requiredIf(function () { return (this.isExistingMember || this.isRequestingToJoin) && !this.email }),
        email: optionalEmail
      },
      DaPhone: {
        required: requiredIf(function () { return (this.isExistingMember || this.isRequestingToJoin) && !this.tel && !this.DaPhone && !this.DaMobile }),
        validPhone () { return this.isValidNumberDaPhone }
      },
      DaMobile: {
        required: requiredIf(function () { return (this.isExistingMember || this.isRequestingToJoin) && !this.tel && !this.DaPhone && !this.DaMobile }),
        validPhone () { return this.isValidNumberDaMobile }
      },
      DaOptinEmail: {
        required: requiredIf(function () { return this.DaOptinEmail!==true && this.DaOptinEmail!==false }),
      },
      DaOptinMobile: {
        required: requiredIf(function () { return this.DaOptinMobile!==true && this.DaOptinMobile!==false }),
      },
      DaOptinPhone: {
        required: requiredIf(function () { return this.DaOptinPhone!==true && this.DaOptinPhone!==false }),
      },
      party: {
        required
      },
      DaGlobalPresidentialPrimary: {
        required: requiredIf(function () { return (this.isExistingMember || this.isRequestingToJoin) && this.isRequestingGPPBallot === null })
      },
      sex: {
        required: requiredIf(function() {
          return this.stateRules.gender_is_required
        })
      },
      isRegistered: {
        required
      },
      identification: {
        ssn: {
          required: requiredIf(function (model) {
            return this.idOptions.includes('SSN') && !model.stateId && !model.ssn4 && !model.ssn9 && !model.noId
          }),
          correctLength: (value) => !helpers.req(value) || value.replace(/[^\d]/gi, '').length === 9
        },
        ssn4: {
          required: requiredIf(function (model) {
            return !model.stateId && !model.ssn && !model.noId && this.idOptions.includes('SSN4')
          }),
          correctLength: (value) => !helpers.req(value) || value.replace(/[^\d]/gi, '').length === 4
        },
        ssn9: {
          required: requiredIf(function (model) {
            return !model.stateId && !model.ssn && !model.noId && this.idOptions.includes('SSN9')
          }),
          correctLength: (value) => !helpers.req(value) || value.replace(/[^\d]/gi, '').length === 9
        },
        stateId: {
          required: requiredIf(function (model) {
            return !model.ssn9 && !model.ssn4 && !model.ssn && !model.noId && this.idOptions.filter(x => !/SSN/.test(x)).length > 0
          })
        }
      },
      fwdAdr: {
        country: { },
        A: {
          required: addressPartRequired('A', 'fwdAdr'),
          maxLength: maxLength(this.MAXLENGTH_FIELDS.A)
        },
        B: {
          required: addressPartRequired('B', 'fwdAdr'),
          maxLength: maxLength(this.MAXLENGTH_FIELDS.B)
        },
        C: {
          required: addressPartRequired('C', 'fwdAdr'),
          maxLength: maxLength(this.MAXLENGTH_FIELDS.C)
        },
        D: {
          required: addressPartRequired('D', 'fwdAdr'),
          maxLength: maxLength(this.MAXLENGTH_FIELDS.D)
        },
        S: {
          required: addressPartRequired('S', 'fwdAdr'),
          maxLength: maxLength(this.MAXLENGTH_FIELDS.S)
        },
        X: {
          required: addressPartRequired('X', 'fwdAdr'),
          maxLength: maxLength(this.MAXLENGTH_FIELDS.X)
        },
        Z: {
          required: addressPartRequired('Z', 'fwdAdr'),
          maxLength: maxLength(this.MAXLENGTH_FIELDS.Z),
          postalCode: postalCode(this.fwdAdr ? this.fwdAdr : null, 'fwdAdr')
        },
        countryiso: {},
        alt1: {},
        alt2: {},
        alt3: {},
        alt4: {},
        alt5: {},
        usesAlternateFormat: {}
      },
      addlInfo: {
      },
      stateSpecial: {
        required: requiredIf(function () {
          let atLeastOneEnabledAndTriggered = false
          for (let ii=0; ii<this.stateRules.specialinput.length; ii++) {
            let isenabled = this.stateRules.specialinput[ii].isenabled
            if (isenabled) {
              let isTriggered = this.isStateRulesSpecialInputTriggered (this.stateRules.specialinput[ii],
                                this.voterIsRegistered, this.voterClass, this.party, this.identificationStatus)
              if (isTriggered) {
                atLeastOneEnabledAndTriggered = true
              }
            }
          }
          return atLeastOneEnabledAndTriggered
        })
      }
    }
  }
}
